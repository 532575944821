import _ from 'lodash';
import toastr from 'toastr';

import { createHttp } from 'utils/http';
import dealsInventoryActions from '../actions';
import dealAPIActions from '../../../../states/resources/deals/actions';

const http = createHttp();

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const serialize = draft => {
    const serializedDraft = { ...draft };
    if (serializedDraft.supply_source === '') {
        serializedDraft.supply_source = null;
    }
    if (serializedDraft.advertiserId === '') {
        serializedDraft.advertiserId = null;
    }
    return {
        ...serializedDraft,
        price_local: _.toNumber(serializedDraft.price_local),
    };
};


const actions = {
    openInventoryDealsForm: () => {
        return dispatch => {
            dispatch({
                type: 'INVENTORY_DEALS_MODAL__OPEN_FORM',
            });
        };
    },
    closeInventoryDealsModal: () => {
        return dispatch => {
            dispatch({
                type: 'INVENTORY_DEALS_MODAL__CLOSE_MODAL',
            });
        };
    },
    showDealDetails: dealId => {
        return async dispatch => {
            dispatch({
                type: 'INVENTORY_DEALS_MODAL__INIT_DETAILS',
            });

            const query = `
                query fetchAllDeals($dealId: String) {
                    deals(filter: {dealIds: [$dealId]}) {
                        id
                        app_name
                        price_local
                        currency
                        pub_name
                        supply_source
                        type
                        advertiserId
                    }
                }
            `;
            const variables = {
                dealId,
            };

            try {
                const { deals } = await http.graphql(query, variables);
                dispatch({
                    type: 'INVENTORY_DEALS_MODAL__DETAILS_DATA_END',
                    payload: { deal: deals[0] },
                });
            } catch (error) {
                dispatch({
                    type: 'INVENTORY_DEALS_MODAL__DETAILS_DATA_ERROR',
                });
                if (error.response.status === 401) {
                    return;
                }
                toastr.warning(
                    '',
                    '<p>Something went wrong, please try again later. The EngageFront team has been notified</p>',
                    TOASTR_OPTIONS
                );
                if (window.bugsnagClient) {
                    window.bugsnagClient.notify(
                        `Failed to fetch organization tech fee data in Media Plan Form`,
                        {
                            metaData: {
                                orgId: `${orgId}`,
                            },
                        }
                    );
                }
            }
        };
    },

    updateField(fieldName, fieldValue) {
        return dispatch => {
            dispatch({
                type: 'INVENTORY_DEALS_MODAL_UPDATE_FORM_FIELD',
                payload: {
                    fieldName,
                    fieldValue,
                },
            });
        };
    },
    submitForm(isDetailsMode, dealId) {
        return async (dispatch, getState) => {
            const draft = _.get(getState(), 'dealsInventoryModal.draft');
            const errors = _.get(getState(), 'dealsInventoryModal.errors');

            if (Object.keys(errors).length > 0) {
                return dispatch({
                    type: 'INVENTORY_DEALS_MODAL_SHOW_FIELD_ERRORS',
                });
            }

            const payload = serialize(draft);

            try {
                if (isDetailsMode) {
                    await dispatch(dealAPIActions.update(dealId, payload));
                } else {
                    await dispatch(dealAPIActions.create(payload));
                }
                dispatch({
                    type: 'INVENTORY_DEALS_MODAL_SUBMIT_DRAFT_SUCCESS',
                });
                dispatch(dealsInventoryActions.fetchAllDeals());
            } catch (error) {
                dispatch({
                    type: 'INVENTORY_DEALS_MODAL_SUBMIT_DRAFT_ERROR',
                    payload: { error }
                });
            }
        };
    },
};

export default actions;
