import _ from 'lodash';
import React from 'react';

import { MultiTreeView } from 'widgets-v6/treeview';
import { IncludeExcludeLayout } from 'widgets-v6/layout';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';

const MultiSelectWrapper = ({
    onChange,
    type,
    fieldName,
    options,
    value,
    marginBottom,
    disabledOptions,
}) => {
    return (
        <IncludeExcludeLayout type={type} marginBottom={marginBottom}>
            <MultiTreeView
                key={fieldName}
                placeholder="Add a Venue Type"
                value={value}
                onChange={value => onChange({ field: fieldName, value })}
                disabledOptions={disabledOptions}
                option={options}
            />
        </IncludeExcludeLayout>
    );
};

export const TargetDoohVenues = ({
    onFieldChange,
    options,
    doohVenuesIncludeValue,
    doohVenuesExcludeValue,
    doohVenuesWarning,
}) => {
    const handleChange = ({ field, value }) => {
        onFieldChange(field, value);
    };

    const getOptionsExcludedFromList = (options, list) => {
        if (list.length === 0) {
            return options;
        }

        const disabledItems = [];

        _.each(options, function(optionValue, optionKey) {
            if (list.includes(optionKey)) {
                disabledItems.push(optionKey);
            }
        });
        return disabledItems;
    };

    return (
        <React.Fragment>
            <MultiSelectWrapper
                onChange={handleChange}
                type="Include"
                placeholder="Select DOOH Venues to include"
                fieldName="target_dooh_venues"
                options={options}
                value={doohVenuesIncludeValue}
                disabledOptions={getOptionsExcludedFromList(options, doohVenuesExcludeValue)}
                marginBottom
            />
            <MultiSelectWrapper
                onChange={handleChange}
                type="Exclude"
                placeholder="Select DOOH Venues to exclude"
                fieldName="target_dooh_venues_exclude"
                options={options}
                disabledOptions={getOptionsExcludedFromList(options, doohVenuesIncludeValue)}
                value={doohVenuesExcludeValue}
            />
            {doohVenuesWarning.hasWarning && isCrossPlatformCampaign && (
                <React.Fragment>
                    <br />
                    <WarningText
                        severity={doohVenuesWarning.severity}
                        message={doohVenuesWarning.message}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export const DoohVenueTypeSummary = ({ doohVenuesIncludeValue, doohVenuesExcludeValue }) => {
    if (_.isEmpty(doohVenuesIncludeValue)) {
        doohVenuesIncludeValue = ['All'];
    }
    if (_.isEmpty(doohVenuesExcludeValue)) {
        doohVenuesExcludeValue = ['None'];
    }
    return (
        <React.Fragment>
            <IncludeExcludeLayout type="Include" marginBottom>
                {doohVenuesIncludeValue}
            </IncludeExcludeLayout>
            <IncludeExcludeLayout type="Exclude">{doohVenuesExcludeValue}</IncludeExcludeLayout>
        </React.Fragment>
    );
};
