import _ from 'lodash';
import moment from 'moment';
import { makeActions } from 'utils/redux-tools';
import { audienceDimensionIsHidden } from '../services/business-logic';

export const REDUCER_KEY = 'businessReportEditor';
export const initialState = {};

const checkValidDates = (lastFrame, last) => {
    const startDate = moment()
                .subtract(last, lastFrame)
                .startOf('day')
    return startDate.isAfter('2023-04-01');
}

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        updateFilterDateRangeMode: (state, { filterType, type }) => {
            let changes = {};
            if (type === 'dynamic') {
                changes = { type, last: 1, lastFrame: 'hours', start: null, end: null };
            } else if (type === 'preset') {
                changes = {
                    type,
                    last: null,
                    lastFrame: null,
                    start: moment()
                        .startOf('month')
                        .format('YYYY-MM-DDTHH:mm:ss'),
                    end: moment()
                        .endOf('month')
                        .format('YYYY-MM-DDTHH:mm:ss'),
                };
            }
            return {
                ...state,
                draft: updateFilters(state.draft, filterType, changes),
            };
        },
        changeFilterMode: (state, { filterType, mode }) => {
            return {
                ...state,
                draft: updateFilters(state.draft, filterType, { mode }),
            };
        },
        updateFilterLast: (state, { filterType, last }) => {
            const datesAreValid = checkValidDates(state.draft.dateRange.lastFrame, last);
            return {
                ...state,
                errors: datesAreValid ? {} : {
                    ...state.errors,
                    dateRange: 'Invalid dates selected due to selected dimensions.'
                },
                draft: updateFilters(state.draft, filterType, { last }),
            }
        },
        updateLastFrame: (state, { filterType, lastFrame }) => {
            const datesAreValid = checkValidDates(lastFrame, state.draft.dateRange.last);
            return {
                ...state,
                errors: datesAreValid ? {} : {
                    ...state.errors,
                    dateRange: 'Invalid dates selected due to selected dimensions.'
                },
                draft: updateFilters(state.draft, filterType, { lastFrame }),
            }
        },
        updateFilterFrom: (state, { filterType, from }) => {
            return {
                ...state,
                draft: updateFilters(state.draft, filterType, { from }),
            };
        },
        updatePresetModeDateRange: (state, args) => {
            const { filterType, month, campaignOptions, engagementOptions } = args;
            const { campaigns, dateRange, dimensions, attributes } = state.draft;
            let nextDraft = { ...state.draft };

            if (audienceDimensionIsHidden(campaignOptions, campaigns, dateRange)) {
                let filteredDimensions = _.filter(
                    dimensions,
                    dimension => dimension !== 'audience_segment'
                );
                let filteredAttributes = _.filter(
                    attributes,
                    attribute => attribute !== 'audience_segment_type_attr'
                );

                nextDraft = {
                    ...nextDraft,
                    dimensions: filteredDimensions,
                    attributes: filteredAttributes,
                };

                if (engagementOptions) {
                    // Ensure that engagements in the draft exist as Engagement Options
                    const engagementOptionValues = _.map(engagementOptions, opt => opt.value);
                    const engagements = _.intersection(
                        nextState.draft.engagements,
                        engagementOptionValues
                    );
                    nextDraft = { ...nextDraft, engagements };
                }
            }
            return {
                ...state,
                draft: updateFilters(nextDraft, filterType, {
                    start: month.start,
                    end: month.end,
                    type: 'preset',
                }),
            };
        },
        updateAutomaticModeDateRange: (state, { filterType }) => {
            return {
                ...state,
                draft: updateFilters(state.draft, filterType, {
                    start: null,
                    end: null,
                    type: 'automatic',
                }),
            };
        },
        updateCustomModeDateRange: (state, { filterType, start, end }) => {
            return {
                ...state,
                draft: updateFilters(state.draft, filterType, {
                    start,
                    end,
                    type: 'custom',
                }),
            };
        },
        toggleFilter: (state, { filterType }) => {
            const orgType = state.ownOrg.type;
            const nextDraft = { ...state.draft };

            nextDraft[filterType] = {
                ...nextDraft[filterType],
                isEnabled: !nextDraft[filterType].isEnabled,
            };

            let isAllDisabled;
            if (orgType === 'admin') {
                isAllDisabled =
                    nextDraft.dateRange.isEnabled === false &&
                    nextDraft.adEndDate.isEnabled === false &&
                    nextDraft.organizationFilter.isEnabled === false &&
                    nextDraft.campaigns.isEnabled === false;
            } else {
                isAllDisabled =
                    nextDraft.dateRange.isEnabled === false &&
                    nextDraft.adEndDate.isEnabled === false &&
                    nextDraft.campaigns.isEnabled === false;
            }

            // We must have at least 1 option selected
            if (isAllDisabled) {
                return state;
            }

            return {
                ...state,
                searchFilter: '',
                selectAll: false,
                draft: nextDraft,
            };
        },
        setSelectedTab: (state, selectedTab) => ({
            ...state,
            selectedTab,
        }),
        setSearchFilter: (state, filter) => ({
            ...state,
            searchFilter: filter,
        }),

        handleSelectAll: (state, { filteredCampaigns, selectAll }) => {
            const newSelectAll = !selectAll;
            const availabIdsList = _.map(filteredCampaigns, item => item.value);
            const ids = {
                ids: newSelectAll ? state.draft.campaigns.ids.concat(availabIdsList) : [],
            };
            return {
                ...state,
                draft: updateFilters(state.draft, 'campaigns', ids),
                selectAll: !state.selectAll,
            };
        },
        handleItemToggle: (state, { item }) => {
            const campaign = item;
            const campaignFilter = state.draft.campaigns;
            let selectedItems;
            if (_.includes(campaignFilter.ids, campaign.value)) {
                selectedItems = _.filter(campaignFilter.ids, id => id !== campaign.value);
            } else {
                selectedItems = campaignFilter.ids.concat(campaign.value);
            }
            return {
                ...state,
                draft: updateFilters(state.draft, 'campaigns', { ids: selectedItems }),
            };
        },
        selectOrganization: (state, organizationIds) => {
            const { organizationFilter } = state.draft;
            let finalItems = _.union(organizationIds, organizationFilter);
            return {
                ...state,
                draft: updateFilters(state.draft, 'organizationFilter', { ids: finalItems }),
            };
        },
        init: state => state,
        initSuccess: (state, { campaigns, campaignScroll, hasMore }) => ({
            ...state,
            campaigns,
            campaignScroll,
            hasMore,
        }),
        toggleShowSpendOverdeliveries: state => ({
            ...state,
            draft: {
                ...state.draft,
                showSpendOverdeliveries: !state.draft.showSpendOverdeliveries,
            },
        }),
        toggleMetric: (state, metricKey) => {
            const { draft } = state;

            let metrics;
            if (_.includes(draft.metrics, metricKey)) {
                metrics = _.filter(draft.metrics, metric => metric !== metricKey);
            } else {
                metrics = [...draft.metrics, metricKey];
            }

            return {
                ...state,
                draft: {
                    ...draft,
                    metrics,
                },
            };
        },
        toggleSubevent: (state, payload) => {
            return {
                ...state,
                selectedSubevents: payload.values,
            }
        },
        fetchMoreCampaigns: state => {
            return {
                ...state,
                campaignScroll: state.campaignScroll + 1,
            };
        },
        fetchCampaignsSummary: (state) => {
            return {
                ...state,
                campaignsSummary: [],
            }
        },
        fetchCampaignsSummarySuccess: (state, { campaigns }) => {
            return {
                ...state,
                campaignsSummary: campaigns,
            }
        },
    },
});

function updateFilters(draft, filterType, changes = {}) {
    if (!draft[filterType]) {
        return draft;
    }

    return {
        ...draft,
        [filterType]: {
            ...draft[filterType],
            ...changes,
        },
    };
}
