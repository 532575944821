import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import shallowCompare from 'utils/shallow-compare';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import BaseColumnForm from '../base-column-form';
import Section from 'widgets-v5/section';
import { Typography } from '@mui/material';

import { MultiSelect } from 'widgets-v6/select';

import selector, { formSelector } from './selector';

import actions from 'forms/media-plan-form/actions';

class PrivateDealsSummaryComponent extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { dealsInAd } = this.props;

        return (
            <div>
                <Typography>{_.map(dealsInAd, deal => _.get(deal, 'app_name'))}</Typography>
            </div>
        );
    }
}
export const PrivateDealsSummary = connect(selector)(PrivateDealsSummaryComponent);

const PrivateDealsFormComponent = props => {
    const { ad, ads, campaign, onSave, dealsByOrganization, dispatch } = props;

    const options = _.map(dealsByOrganization, deal => ({
        label: deal.app_name,
        value: deal.id,
    }));

    const onSavePrivateDeals = async () => {
        if (ads && ads.length > 0) {
            await Promise.all(
                _.map(ads, async ad => {
                    dispatch(actions.fetchAdDeals(ad.id));
                })
            );
        } else {
            await dispatch(actions.fetchAdDeals(ad.id));
        }
        onSave();
    };

    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline="Private Deals"
            fields={['deals']}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSavePrivateDeals}
            onCancel={onSave}
            columnName="Private Deals"
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                return (
                    <FormControl fullWidth margin="normal">
                        <FormLabel>Private Deals</FormLabel>
                        <MultiSelect
                            value={draft.deals}
                            onChange={value => updateDraft({ deals: value })}
                            options={options}
                            placeholder="Select private deals"
                        />
                    </FormControl>
                );
            }}
        </BaseColumnForm>
    );
};

export const PrivateDealsForm = connect(formSelector)(PrivateDealsFormComponent);
