import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import numeral from 'numeral';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';

import { SearchInput } from 'widgets-v6/standard-input';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import { MultiSelect } from 'widgets-v6/select';

import DealModal from './deal-modal';
import DeleteDealModal from './delete-deal-modal';
import inventoryDealModalActions from './deal-modal/actions';
import deleteDealModalActions from './delete-deal-modal/actions';
import dealsInventoryActions from './actions';
import selector from './selector';
import { getCurrencySignforCurrency, formatDate_dateWithYear } from 'utils/formatting';

const useStyles = makeStyles(theme => ({
    padded: {
        padding: `0 ${theme.spacing(3)}`,
    },
    tableContainer: {
        padding: `0 ${theme.spacing(3)}`,
        margin: `${theme.spacing(3)} 0`,
    },
    textContainer: {
        marginBottom: theme.spacing(1.5),
    },
}));

const tableStyles = makeStyles(() => ({
    overflowTable: {
        overflowX: 'auto',
    },
}));

const DealsInventory = ({
    dispatch,
    deals,
    loading,
    keywords,
    sort,
    timezone,
    advertiserOptions,
    filters,
}) => {
    const classes = useStyles();
    useEffect(() => {
        dispatch(dealsInventoryActions.fetchAllDeals());
    }, []);

    const openDealsModal = () => {
        dispatch(inventoryDealModalActions.openInventoryDealsForm());
    };

    const onChange = e => {
        const { value } = e.target;
        dispatch(dealsInventoryActions.fetchAllDeals(value, sort));
    };

    const onSort = column => {
        const isAscending = sort.field === column && sort.order === 1;
        dispatch(
            dealsInventoryActions.fetchAllDeals(keywords, {
                field: column,
                order: isAscending ? -1 : 1,
            })
        );
    };

    return (
        <div>
            <Toolbar>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Typography variant="h4">Private Deals</Typography>
                </Grid>
            </Toolbar>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.padded}
            >
                <Grid item xs={3}>
                    <SearchInput placeholder="Search" value={keywords} onChange={onChange} />
                </Grid>
                <Grid item xs="auto">
                    <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        onClick={openDealsModal}
                        color="primary"
                    >
                        New Deal
                    </Button>
                </Grid>
            </Grid>
            <Container disableGutters className={classes.tableContainer} maxWidth={false}>
                <BlockLoadGroup isLoading={loading}>
                    <div className={classes.textContainer}>
                        <Typography variant="body2">{deals.length} Records found.</Typography>
                    </div>
                    <div>
                        <DealsTable
                            deals={deals}
                            dispatch={dispatch}
                            onSort={onSort}
                            sortColumn={sort.field}
                            sortOrder={sort.order === -1 ? 'desc' : 'asc'}
                            timezone={timezone}
                            advertiserOptions={advertiserOptions}
                            filters={filters}
                            keywords={keywords}
                            sort={sort}
                        />
                    </div>
                </BlockLoadGroup>
            </Container>
            <DealModal advertiserOptions={advertiserOptions} />
            <DeleteDealModal />
        </div>
    );
};

const typeMapping = {
    private_auction: 'Private Auction',
    private_deal: 'Preferred Deal',
};

const fields = [
    {
        label: 'App/Site Name',
        id: 'app_name',
        align: 'left',
        getValue: ({ deal }) => {
            return deal.app_name;
        },
    },
    {
        label: 'Deal ID',
        id: 'id',
        align: 'left',
        getValue: ({ deal }) => {
            return deal.id;
        },
    },
    {
        label: 'Type',
        id: 'type',
        align: 'left',
        getValue: ({ deal }) => {
            return typeMapping[deal.type];
        },
    },
    {
        label: 'Publisher Name',
        id: 'pub_name',
        align: 'left',
        getValue: ({ deal }) => {
            return deal.pub_name;
        },
    },
    {
        label: 'Advertiser',
        id: 'advertiser',
        align: 'left',
        getValue: ({ deal, advertiserOptions }) => {
            const advertiserId = deal.advertiserId;
            const matchedAdvertiser = _.find(
                advertiserOptions,
                advertiser => advertiser.value === advertiserId
            );

            if (matchedAdvertiser) {
                return matchedAdvertiser.label;
            }
            return null;
        },
    },
    {
        label: 'Supply Source',
        id: 'supply_source',
        align: 'left',
        getValue: ({ deal }) => {
            return deal.supply_source;
        },
    },
    {
        label: 'Price',
        id: 'price',
        align: 'right',
        getValue: ({ deal }) => {
            const currencyString = numeral(deal.price_local).format('0,0.00');
            const currencySign = getCurrencySignforCurrency(deal.currency);
            return `${deal.currency} ${currencySign}${currencyString} `;
        },
    },
    {
        label: 'Created On',
        id: '_created',
        align: 'left',
        getValue: ({ deal, timezone }) => {
            if (deal._created) {
                return formatDate_dateWithYear(deal._created, timezone);
            }
        },
    },
    {
        label: 'Actions',
        id: 'actions',
        align: 'right',
        getValue: ({ deal, openDealModalWithDetails, openDeleteDealModal }) => {
            return (
                <div>
                    {' '}
                    <IconButton onClick={() => openDealModalWithDetails(deal.id)} size="small">
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => openDeleteDealModal(deal.id, deal.app_name)}
                        size="small"
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            );
        },
    },
];
const DealsTable = ({
    deals,
    dispatch,
    sortColumn,
    onSort,
    sortOrder,
    timezone,
    advertiserOptions,
    keywords,
    sort,
    filters,
}) => {
    const classes = tableStyles();

    const openDealModalWithDetails = dealId => {
        dispatch(inventoryDealModalActions.showDealDetails(dealId));
    };

    const openDeleteDealModal = (dealId, appName) => {
        dispatch(deleteDealModalActions.openDeleteModal(dealId, appName));
    };

    return (
        <Paper>
            <Toolbar>
                <Grid container alignItems="center" spacing={1} justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">All Deals</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Filters
                            advertiserOptions={advertiserOptions}
                            filters={filters}
                            keywords={keywords}
                            sort={sort}
                        />
                    </Grid>
                </Grid>
            </Toolbar>
            <div className={classes.overflowTable}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {_.map(fields, field => (
                                <TableCell
                                    sortDirection={sortColumn === field.id ? sortOrder : false}
                                    align={field.align}
                                    key={field.id}
                                >
                                    <TableSortLabel
                                        active={sortColumn === field.id}
                                        direction={sortColumn === field.id ? sortOrder : 'asc'}
                                        onClick={() => onSort(field.id)}
                                    >
                                        {field.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(deals, deal => (
                            <TableRow key={deal.id}>
                                {_.map(fields, field => (
                                    <TableCell align={field.align} key={field.id}>
                                        {field.getValue({
                                            deal,
                                            timezone,
                                            openDealModalWithDetails,
                                            openDeleteDealModal,
                                            advertiserOptions,
                                        })}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    );
};

const filterOptions = [
    {
        label: 'Advertiser',
        component: ({ filters, handleChange, advertiserOptions }) => (
            <MultiSelect
                placeholder="Filter by advertiser"
                value={filters.advertiserIds}
                onChange={value => handleChange({ advertiserIds: value })}
                options={advertiserOptions}
                fullWidth={true}
            />
        ),
    },
];

const Filters = ({ filters, advertiserOptions }) => {
    const dispatch = useDispatch();

    const handleChange = selectedFilters => {
        dispatch(dealsInventoryActions.applyFilters(selectedFilters));
    };
    return (
        <React.Fragment>
            {_.map(filterOptions, (filter, index) => (
                <FormControl margin="dense" key={index} fullWidth>
                    {filter.component({
                        filters,
                        handleChange,
                        advertiserOptions,
                    })}
                </FormControl>
            ))}
        </React.Fragment>
    );
};

export default connect(selector)(DealsInventory);
