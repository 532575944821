import _ from 'lodash';
import { GraphQLClient } from 'graphql-request';
import toastr from 'toastr';

import { createHttp, getNextBase } from 'utils/http';

const http = createHttp();
const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const actions = {
    fetchAllDeals: (keywords = '', sort = {}) => {
        return async (dispatch, getState) => {
            dispatch({
                type: 'INVENTORY_DEALS_TABLE__FETCH_START',
                payload: { keywords, sort },
            });
            await dispatch(actions.getAdvertisers());

            const { filters } = _.get(getState(), 'dealsTable');

            const query = `
                query getAllDeals($keywords: String, $sort: Sort, $advertiserIds: [String]) {
                    deals(filter: { keywords: $keywords, sort: $sort, advertiserIds:  $advertiserIds}) {
                        id
                        app_name
                        price_local
                        currency
                        pub_name
                        advertiserId
                        supply_source
                        type
                        _created
                    }
                    ownOrganization {
                        default_timezone
                    }
                }
            `;

            const variables = {
                keywords,
                sort,
                advertiserIds: filters.advertiserIds,
            };

            try {
                const { deals, ownOrganization } = await http.graphql(query, variables);
                dispatch({
                    type: 'INVENTORY_DEALS_TABLE__FETCH_END',
                    payload: { deals, timezone: _.get(ownOrganization, 'default_timezone') },
                });
            } catch (error) {
                console.log('error occured while fetching deals', error);
                dispatch({
                    type: 'INVENTORY_DEALS_TABLE__FETCH_ERROR',
                    payload: {},
                });
                if (error.response.status === 401) {
                    return;
                }
                toastr.warning(
                    '',
                    '<p>Something went wrong, please try again later. The EngageFront team has been notified</p>',
                    TOASTR_OPTIONS
                );
                if (window.bugsnagClient) {
                    window.bugsnagClient.notify(
                        `Failed to fetch organization tech fee data in Media Plan Form`,
                        {
                            metaData: {
                                orgId: `${orgId}`,
                            },
                        }
                    );
                }
            }
        };
    },
    getAdvertisers: () => {
        return async (dispatch, getState) => {
            try {
                const graphqlUrl = getNextBase() + 'graphql';

                const client = new GraphQLClient(graphqlUrl, {
                    headers: {
                        ...http._getHeaders(),
                        Authorization: _.get(getState(), 'profile.authToken.auth_token'),
                    },
                });
                const query = `
                    {
                        organizations(filters: {relationship: "child", type: ["client", "co_managed_client"], client_type: ["advertiser"]}) {
                            id
                            name
                            type
                            client_type
                        }
                    }
                `;
                const data = await client.request(query);

                let advertisers = data.organizations;
                dispatch({
                    type: 'INVENTORY_DEALS_MODAL__FETCH_ADVERTISERS_SUCESS',
                    payload: { advertisers },
                });
            } catch (error) {
                console.log('GraphQL organization query error while getting advertisers', error);
                throw error;
            }
        };
    },
    applyFilters: selectedFilters => {
        return (dispatch, getState) => {
            dispatch({
                type: 'INVENTORY_DEALS_MODAL__APPLY_FILTERS',
                payload: selectedFilters,
            });
            dispatch(actions.fetchAllDeals());
        };
    },
    closeInventoryDealsModal: () => {
        return dispatch => {
            dispatch({
                type: 'INVENTORY_DEALS_MODAL__CLOSE_MODAL',
            });
        };
    },
};

export default actions;
