import _ from 'lodash';
import Joi from '@hapi/joi';

export default Joi.object({
    app_name: Joi.string()
        .required()
        .label('App/Site Name')
        .messages({
            'string.empty': 'App/Site Name is required',
        }),

    id: Joi.string()
        .required()
        .label('Deal ID')
        .messages({
            'string.empty': 'Deal ID is required',
        })
        .custom((value, helper) => {
            const validChars = new RegExp(/^[A-Za-z0-9\.\-_]+$/, 'gm');
            return (
                validChars.test(value) ||
                helper.message(
                    'Deal ID can only contain letters, numbers, dots (.), underscores (_), and dashes (-).'
                )
            );
        }),

    pub_name: Joi.string()
        .required()
        .label('Publisher Name')
        .messages({
            'string.empty': 'Publisher Name is required',
        }),

    price_local: Joi.number()
        .required()
        .greater(0)
        .label('Price')
        .messages({
            'number.greater': 'Price must be greater than 0',
            'number.base': 'Price is required',
        }),

    supply_source: Joi.string()
        .required()
        .label('Supply Source')
        .messages({
            'any.required': 'Supply Source is required',
        }),
});
