import React from 'react';
import { Route, IndexRoute, IndexRedirect } from 'react-router';

import Root from 'pages/root';
import { Overview } from 'pages/overview';
import { MainDashboard, SelfServeDashboard } from 'pages/overview';
import Dashboard from 'pages/dashboard';
import Account from 'pages/account';
import Activate from 'pages/activate';
import AdminV1 from 'pages/admin';
import AdminV2 from 'pages/admin-v2';
import Profile from 'pages/profile';
import ReportsDashboard from 'pages/reports/dashboard';
import DevSettings from 'containers/dev-settings';

import StatusPage from 'pages/status';
import Audiences from 'pages/audiences';
import AudiencesDashboard from 'pages/audiences/dashboard';
import AudienceEditor from 'pages/audiences/editor';

import Campaign from 'pages/campaigns/campaign';
import CampaignAccess from 'containers/campaign-access-check';
import CampaignOverview from 'pages/campaigns/campaigns-overview';
import CampaignOverviewWorkspace from 'pages/campaigns/campaigns-overview/modules/workspace';
import CampaignHistory from 'pages/campaigns/campaign-history';
import CampaignsNew from 'pages/campaign-form-new';

import AdsOverview from 'pages/campaigns/ads/ads-overview';
import AdHistory from 'pages/campaigns/ads/ad-history';
import CreativesOverview from 'pages/campaigns/creatives/creatives-overview';
import CreativeHistory from 'pages/campaigns/creatives/creatives-history';
import CreativesNewForm, {
    provideCreativeNew,
} from 'pages/campaigns/creatives/form/creative-new-form';
import CreativesEditForm, {
    provideCreativeEdit,
} from 'pages/campaigns/creatives/form/creative-edit-form';
import FilesOverview from 'pages/campaigns/files-overview';
import BeaconsOverview from 'pages/campaigns/beacons-overview';
import ConversionsOverview from 'pages/campaigns/conversions-overview';
import CustomDimensions from 'pages/campaigns/custom-dimensions/custom-dimensions-overview';
import AdNewForm from 'pages/ad-form/ad-new-form';
import AdEditForm from 'pages/ad-form/ad-edit-form';

import CampaignProgress from 'pages/campaigns/progress/campaign-progress';
import AdProgress from 'pages/campaigns/progress/ad-progress';

import FootTrafficAttribution from 'pages/campaigns/report/foot-traffic-attribution';
import ReportOverview from 'pages/campaigns/report/report-overview';
import ReportExplore from 'pages/campaigns/report/report-explore';
import ReportGeo from 'pages/campaigns/report/report-geo';
import ReportChannel from 'pages/campaigns/report/report-channel';

import AdPresetNew from 'pages/ad-preset/ad-preset-new';
import AdPresetEdit from 'pages/ad-preset/ad-preset-edit';

import Presets from 'pages/presets';
import PresetsPoiDashboard from 'pages/presets/poi-dashboard';
import PresetsPoiEditor from 'pages/presets/poi-editor';

import { PredictionEditorLazy } from 'pages/prediction-editor/v2';

import ResetPassword from 'pages/reset-password';
import ForgotPassword from 'pages/forgot-password';

import Help from 'pages/help';
import HelpTopic from 'pages/help/help-topic';
import HelpTopicList from 'pages/help/help-topic-list';

import AppsAndSitesList from 'pages/apps-and-sites/apps-and-sites-list';
import AppsAndSitesPage from 'pages/apps-and-sites/landing';
import AppsReport from 'pages/apps-and-sites/apps-report';
import SitesReport from 'pages/apps-and-sites/sites-report';
import DealsInventoryPage from 'pages/apps-and-sites/deals-inventory';

import FlagsPage from 'pages/flags';

import RetailDashboard from 'pages/audiences/retail-dashboard';
import BrandDashboard from 'pages/audiences/brand-dashboard';
import EnvironicsDashboard from 'pages/audiences/environics-dashboard';
import MastercardDashboard from 'pages/audiences/mastercard-dashboard';
import BehaviourDashboard from 'pages/audiences/behaviour-dashboard';
import ManifoldDashboard from 'pages/audiences/manifold-dashboard';
import PDSCustomDashboard from 'pages/audiences/pds-custom-dashboard';
import RetargetingDashboard from 'pages/audiences/retargeting-dashboard';
import UploadedDashboard from 'pages/audiences/uploaded-dashboard';
import IntentDashboard from 'pages/audiences/intent-dashboard';
import AdobeDashboard from 'pages/audiences/adobe-dashboard';
import { PermutiveDashboard } from 'pages/audiences/permutive-dashboard';
import OverallDashboard from 'pages/audiences/overall-dashboard/lazy';
import AgeDashboard from 'pages/audiences/age-dashboard';
import FlexBrandDashboard from 'pages/audiences/flex-brand-dashboard';
import FlexCategoryDashboard from 'pages/audiences/flex-category-dashboard';
import FlexCustomDashboard from 'pages/audiences/flex-custom-dashboard';

import FTALocationLists from 'pages/fta-location-lists';
import FTALocationListsEditor from 'pages/fta-location-lists/editor';
import ConversionsDashboard from 'pages/conversions';
import ProgressGraphLazy from 'packages/pages/campaigns/progress/campaign-progress/progress-graph/lazy.js';
import NotFound from '../packages/pages/not-found';

const routes = (
    <Route path="/" component={Root}>
        <IndexRoute components={{ workspace: Dashboard }} />
        <Route path="home" components={{ workspace: Overview }}>
            <IndexRoute component={MainDashboard} />
            <Route path="self-serve" component={SelfServeDashboard} />
        </Route>
        <Route path="campaigns/new" components={{ workspace: CampaignsNew }} />
        <Route path="apps-and-sites" components={{ workspace: AppsAndSitesPage }}>
            <IndexRoute components={{ workspace: AppsAndSitesList }} />
            <Route path="inventory" components={{ workspace: DealsInventoryPage }} />
            <Route path="report" components={{ workspace: AppsReport }} />
            <Route path="site-report" components={{ workspace: SitesReport }} />
        </Route>
        <Route path="fta-location-lists" components={{ workspace: FTALocationLists }}>
            <Route path="new" components={{ workspace: FTALocationListsEditor }} />
            <Route path="view/:id" components={{ workspace: FTALocationListsEditor }} />
        </Route>

        <Route path="help" components={{ workspace: Help }} />
        <Route path="help/:category" components={{ workspace: HelpTopicList }} />
        <Route path="help/:category/:topic" components={{ workspace: HelpTopic }} />
        <Route
            path="campaigns/:campaignId/setup/ads/new"
            components={{ workspace: CampaignAccess(AdNewForm) }}
        >
            <Route
                path="creatives/new"
                components={{
                    workspace: provideCreativeNew({
                        getReturnUrl(params) {
                            return `/campaigns/${params.campaignId}/setup/ads/new`;
                        },
                    }),
                }}
            />
            <Route
                path="creatives/:creativeId/duplicate"
                components={{
                    workspace: provideCreativeNew({
                        getReturnUrl(params) {
                            return `/campaigns/${params.campaignId}/setup/ads/new`;
                        },
                    }),
                }}
            />

            <Route
                path="creatives/:creativeId/edit"
                components={{
                    workspace: provideCreativeEdit({
                        getReturnUrl(params) {
                            return `/campaigns/${params.campaignId}/setup/ads/new`;
                        },
                    }),
                }}
            />
        </Route>
        <Route
            path="campaigns/:campaignId/setup/ads/:adId/duplicate"
            components={{ workspace: CampaignAccess(AdNewForm) }}
        >
            <Route
                path="creatives/new"
                components={{
                    workspace: provideCreativeNew({
                        getReturnUrl(params) {
                            return `/campaigns/${params.campaignId}/setup/ads/${
                                params.adId
                            }/duplicate`;
                        },
                    }),
                }}
            />
            <Route
                path="creatives/:creativeId/duplicate"
                components={{
                    workspace: provideCreativeNew({
                        getReturnUrl(params) {
                            return `/campaigns/${params.campaignId}/setup/ads/${
                                params.adId
                            }/duplicate`;
                        },
                    }),
                }}
            />
            <Route
                path="creatives/:creativeId/edit"
                components={{
                    workspace: provideCreativeEdit({
                        getReturnUrl(params) {
                            return `/campaigns/${params.campaignId}/setup/ads/${
                                params.adId
                            }/duplicate`;
                        },
                    }),
                }}
            />
        </Route>
        <Route
            path="campaigns/:campaignId/setup/ads/:adId/edit"
            components={{ workspace: CampaignAccess(AdEditForm) }}
        >
            <Route
                path="creatives/new"
                components={{
                    workspace: provideCreativeNew({
                        getReturnUrl(params) {
                            return `/campaigns/${params.campaignId}/setup/ads/${params.adId}/edit`;
                        },
                    }),
                }}
            />
            <Route
                path="creatives/:creativeId/duplicate"
                components={{
                    workspace: provideCreativeNew({
                        getReturnUrl(params) {
                            return `/campaigns/${params.campaignId}/setup/ads/${params.adId}/edit`;
                        },
                    }),
                }}
            />
            <Route
                path="creatives/:creativeId/edit"
                components={{
                    workspace: provideCreativeEdit({
                        getReturnUrl(params) {
                            return `/campaigns/${params.campaignId}/setup/ads/${params.adId}/edit`;
                        },
                    }),
                }}
            />
        </Route>
        <Route path="campaigns/:campaignId" components={{ workspace: CampaignAccess(Campaign) }}>
            <Route path="history" components={{ workspace: CampaignHistory }} />
            <Route path="setup" components={{ workspace: CampaignOverview }}>
                <IndexRoute components={{ workspace: AdsOverview }} />
                <Route path="ads" components={{ workspace: AdsOverview }}>
                    <Route path=":adId/history" components={{ workspace: AdHistory }} />
                </Route>
                <Route path="overview" components={{ workspace: CampaignOverviewWorkspace }} />
                <Route path="creatives" components={{ workspace: CreativesOverview }}>
                    <Route path="new" components={{ workspace: CreativesNewForm }} />
                    <Route
                        path=":creativeId/duplicate"
                        components={{ workspace: CreativesNewForm }}
                    />
                    <Route path=":creativeId/history" components={{ workspace: CreativeHistory }} />
                    <Route path=":creativeId/edit" components={{ workspace: CreativesEditForm }} />
                </Route>
                <Route path="beacons" components={{ workspace: BeaconsOverview }} />
                <Route path="pixels" components={{ workspace: ConversionsOverview }} />
                <Route path="files" components={{ workspace: FilesOverview }} />
                <Route path="custom-dimensions" components={{ workspace: CustomDimensions }} />
            </Route>
            <Route path="progress" components={{ workspace: CampaignProgress }}>
                <Route path="overall" components={{ workspace: ProgressGraphLazy }} />
                <Route path=":adId" components={{ workspace: AdProgress }} />
            </Route>
            <Route path="client-report" components={{ workspace: ReportOverview }}>
                <IndexRoute components={{ workspace: ReportExplore }} />
                <Route path="explore" components={{ workspace: ReportExplore }} />
                <Route path="geo" components={{ workspace: ReportGeo }} />
                <Route path="channel" components={{ workspace: ReportChannel }} />
                <Route
                    path="foot-traffic-attribution"
                    components={{ workspace: FootTrafficAttribution }}
                />
            </Route>
            <Route path="report" components={{ workspace: ReportOverview }}>
                <IndexRoute components={{ workspace: ReportExplore }} />
                <Route path="explore" components={{ workspace: ReportExplore }} />
                <Route path="geo" components={{ workspace: ReportGeo }} />
                <Route path="channel" components={{ workspace: ReportChannel }} />
                <Route
                    path="foot-traffic-attribution"
                    components={{ workspace: FootTrafficAttribution }}
                />
            </Route>
        </Route>
        <Route path="activate" components={{ nonLoginRoutedPage: Activate }} />
        <Route path="forgot-password" components={{ nonLoginRoutedPage: ForgotPassword }} />
        <Route path="reset-password" components={{ nonLoginRoutedPage: ResetPassword }} />

        <Route path="dashboard" components={{ workspace: Dashboard }} />
        <Route path="account" components={{ workspace: Account }} />
        <Route path="reports" components={{ workspace: ReportsDashboard }} />
        <Route path="dev-settings" components={{ workspace: DevSettings }} />
        <Route path="admin" components={{ workspace: AdminV2 }} />
        <Route path="admin/:orgId" components={{ workspace: AdminV1 }} />
        <Route path="profile" components={{ workspace: Profile }} />
        <Route path="ad-preset" components={{ workspace: AdPresetNew }} />
        <Route path="ad-preset/new" components={{ workspace: AdPresetNew }} />
        <Route path="ad-preset/:adPresetId/edit" components={{ workspace: AdPresetEdit }} />
        <Route path="audiences" components={{ workspace: Audiences }}>
            <IndexRoute components={{ workspace: AudiencesDashboard }} />
            <Route path="new" components={{ workspace: AudienceEditor }} />
            <Route path="retail" components={{ workspace: RetailDashboard }} />
            <Route path="brand" components={{ workspace: BrandDashboard }}>
                <IndexRoute components={{ workspace: BrandDashboard }} />
                <Route path=":audienceId" components={{ workspace: BrandDashboard }} />
            </Route>
            <Route path="environics" components={{ workspace: EnvironicsDashboard }}>
                <IndexRoute components={{ workspace: EnvironicsDashboard }} />
                <Route path=":audienceId" components={{ workspace: EnvironicsDashboard }} />
            </Route>
            <Route path="mastercard" components={{ workspace: MastercardDashboard }}>
                <IndexRoute components={{ workspace: MastercardDashboard }} />
                <Route path=":audienceId" components={{ workspace: MastercardDashboard }} />
            </Route>
            <Route path="behaviour" components={{ workspace: BehaviourDashboard }} />
            <Route path="pds-custom" components={{ workspace: PDSCustomDashboard }}>
                <IndexRoute components={{ workspace: PDSCustomDashboard }} />
                <Route path=":audienceId" components={{ workspace: PDSCustomDashboard }} />
            </Route>
            <Route path="retargeting" components={{ workspace: RetargetingDashboard }}>
                <IndexRoute components={{ workspace: RetargetingDashboard }} />
                <Route path=":audienceId" components={{ workspace: RetargetingDashboard }} />
            </Route>
            <Route path="uploaded" components={{ workspace: UploadedDashboard }}>
                <IndexRoute components={{ workspace: UploadedDashboard }} />
                <Route path=":audienceId" components={{ workspace: UploadedDashboard }} />
            </Route>
            <Route path="intent" components={{ workspace: IntentDashboard }} />
            <Route path="intent" components={{ workspace: IntentDashboard }}>
                <IndexRoute components={{ workspace: IntentDashboard }} />
                <Route path=":audienceId" components={{ workspace: IntentDashboard }} />
            </Route>
            <Route path="adobe" components={{ workspace: AdobeDashboard }}>
                <IndexRoute components={{ workspace: AdobeDashboard }} />
                <Route path=":audienceId" components={{ workspace: AdobeDashboard }} />
            </Route>
            <Route path="permutive" components={{ workspace: PermutiveDashboard }}>
                <IndexRoute components={{ workspace: PermutiveDashboard }} />
                <Route path=":audienceId" components={{ workspace: PermutiveDashboard }} />
            </Route>
            <Route path="age" components={{ workspace: AgeDashboard }}>
                <IndexRoute components={{ workspace: AgeDashboard }} />
                <Route path=":audienceId" components={{ workspace: AgeDashboard }} />
            </Route>
            <Route path="all" components={{ workspace: OverallDashboard }} />
            <Route path="flex-custom" components={{ workspace: FlexCustomDashboard }}>
                <IndexRoute components={{ workspace: FlexCustomDashboard }} />
                <Route path=":audienceId" components={{ workspace: FlexCustomDashboard }} />
            </Route>
            <Route path="flex-brand" components={{ workspace: FlexBrandDashboard }}>
                <IndexRoute components={{ workspace: FlexBrandDashboard }} />
                <Route path=":audienceId" components={{ workspace: FlexBrandDashboard }} />
            </Route>
            <Route path="flex-category" components={{ workspace: FlexCategoryDashboard }} />
            <Route path="cana-code" components={{ workspace: ManifoldDashboard }}>
                <IndexRoute components={{ workspace: ManifoldDashboard }} />
                <Route path=":audienceId" components={{ workspace: ManifoldDashboard }} />
            </Route>
            <Route path=":audienceId" components={{ workspace: AudienceEditor }} />
        </Route>
        <Route path="flags" components={{ workspace: FlagsPage }} />
        <Route path="presets" components={{ workspace: Presets }}>
            <IndexRedirect to="pois" />
            <Route path="pois">
                <IndexRoute components={{ workspace: PresetsPoiDashboard }} />
                <Route path="new" components={{ workspace: PresetsPoiEditor }} />
                <Route path=":poiId" components={{ workspace: PresetsPoiEditor }} />
            </Route>
        </Route>
        <Route path="inventory" components={{ workspace: PredictionEditorLazy }} />
        <Route path="status" components={{ workspace: StatusPage }} />
        <Route path="pixels" components={{ workspace: ConversionsDashboard }} />
        <Route path="*" components={{ workspace: NotFound }} />
    </Route>
);

export default routes;
