import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';

import actions from './actions';
import selector from './selector';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';
import { SingleSelect } from 'widgets-v6/select';
import { EXCHANGE_OPTIONS } from 'forms/ad-form/constants';
import { labelExchange } from 'utils/formatting';
import { getEnvironmentSettings, getEnv } from 'services/environment';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    dialogPadding: {
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    dialogTitleRoot: {
        padding: `10px ${theme.spacing(1)} 10px ${theme.spacing(3)}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    red: {
        color: '#FF6584',
    },
    helperText: {
        margin: '8px 0',
    },
    dropdownContainer: {
        marginTop: '8px',
        marginBottom: '4px',
    },
    placeholder: {
        color: 'darkgrey',
        fontWeight: 300,
    },
}));

const options = [
    {
        label: 'Private Auction',
        value: 'private_auction',
    },
    {
        label: 'Preferred Deal',
        value: 'private_deal',
    },
];

let allExchanges = [];
_.forEach(EXCHANGE_OPTIONS, (value) => {
    allExchanges = _.union(allExchanges, value);
});

const exchangeOptions = _(allExchanges)
    .map(exchange => ({
        label: labelExchange(exchange),
        value: labelExchange(exchange),
    }))
    .orderBy(['label'])
    .value();

const getExchangeOptions = () => {
    let result = [...exchangeOptions];

    result = _.concat([], result, {
        label: 'Sharethrough',
        value: 'Sharethrough',
    }, {
        label: 'Vistar',
        value: 'Vistar',
    });

    return result;
};

const showCurrencyOptions = environmentSettings => {
    return environmentSettings.getDealCurrencyOptions().map(option => {
        return (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        );
    });
};

const fields = [
    {
        label: 'App/Site Name',
        key: 'app_name',
        required: true,
        getInput: ({ onChange, error, showErrors, classes, draft }) => {
            return (
                <TextField
                    required
                    fullWidth
                    value={draft.app_name}
                    onChange={e => onChange('app_name', e.target.value)}
                    margin="dense"
                    variant="outlined"
                    placeholder={`Enter App/Site Name`}
                    helperText={showErrors && error}
                    error={showErrors && error ? true : false}
                    FormHelperTextProps={{ classes: { root: classes.helperText } }}
                />
            );
        },
    },
    {
        label: 'Type',
        key: 'type',
        required: true,
        getInput: ({ value, onChange }) => {
            return (
                <RadioGroup value={value} onChange={e => onChange('type', e.target.value)}>
                    {options.map(option => (
                        <FormControlLabel
                            key={option.label}
                            value={option.value}
                            control={<Radio color="primary" />}
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
            );
        },
    },
    {
        label: 'Deal ID',
        key: 'id',
        required: true,
        getInput: ({ onChange, showErrors, error, classes, draft, isDetailsMode }) => {
            return (
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    value={draft.id}
                    onChange={e => onChange('id', e.target.value)}
                    placeholder={`Enter Deal ID`}
                    helperText={showErrors && error}
                    error={showErrors && error ? true : false}
                    FormHelperTextProps={{ classes: { root: classes.helperText } }}
                    disabled={isDetailsMode ? true : false}
                />
            );
        },
    },
    {
        label: 'Publisher Name',
        key: 'pub_name',
        required: true,
        getInput: ({ onChange, showErrors, error, classes, draft }) => {
            return (
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    value={draft.pub_name}
                    onChange={e => onChange('pub_name', e.target.value)}
                    placeholder={`Enter Publisher Name`}
                    helperText={showErrors && error}
                    error={showErrors && error ? true : false}
                    FormHelperTextProps={{ classes: { root: classes.helperText } }}
                />
            );
        },
    },
    {
        label: 'Supply Source',
        key: 'supply_source',
        required: true,
        getInput: ({ onChange, classes, showErrors, error, draft }) => {
            return (
                <div className={classes.dropdownContainer}>
                    <SingleSelect
                        value={draft.supply_source}
                        options={getExchangeOptions()}
                        onChange={value => {
                            onChange('currency', value === 'Google Ad Manager' ? 'CAD' : 'USD');
                            onChange('supply_source', value);
                        }}
                        placeholder="Select a supply source"
                        disabled={getEnv() === 'spain'}
                    />
                    {showErrors && error && <FormHelperText error> {error} </FormHelperText>}
                </div>
            );
        },
    },
    {
        label: 'Price',
        key: 'price_local',
        required: true,
        getInput: ({
            classes,
            onChange,
            showErrors,
            error,
            draft,
            environmentSettings,
            loading,
        }) => {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={6} className={classes.dropdownContainer}>
                        <Select
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            value={draft.currency}
                            disabled
                            displayEmpty
                        >
                            <MenuItem disabled value="">
                                Automatically set
                            </MenuItem>
                            {showCurrencyOptions(environmentSettings)}
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldFormatter
                            key={loading}
                            initialValue={draft.price_local}
                            onChange={e => {
                                if (_.isFinite(Number(e))) {
                                    return onChange('price_local', e);
                                }
                            }}
                            formatIn={v => {
                                if (v === 0) {
                                    return '';
                                } else {
                                    return numeral(v).format('0,0.00');
                                }
                            }}
                            shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                            formatOut={v => numeral(v).format('0,0.00')}
                            renderInput={({ value, onChange, onBlur }) => (
                                <TextField
                                    required
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    placeholder={`Enter Price`}
                                    helperText={showErrors && error}
                                    error={showErrors && error ? true : false}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            );
        },
    },
    {
        label: 'Advertiser',
        key: 'advertiser',
        required: false,
        getInput: ({ onChange, classes, showErrors, error, draft, advertiserOptions }) => {
            return (
                <div className={classes.dropdownContainer}>
                    <SingleSelect
                        value={draft.advertiserId}
                        options={advertiserOptions}
                        onChange={value => onChange('advertiserId', value)}
                        placeholder="Filter by advertiser"
                    />
                    {showErrors && error && <FormHelperText error> {error} </FormHelperText>}
                </div>
            );
        },
    },
];

const DealModal = ({
    open,
    dispatch,
    isDetailsMode,
    deal,
    loading,
    draft,
    errors,
    showErrors,
    advertiserOptions,
}) => {
    const classes = useStyles();
    const environmentSettings = getEnvironmentSettings();

    const onClose = () => {
        dispatch(actions.closeInventoryDealsModal());
    };
    const onChange = (field, value) => {
        dispatch(actions.updateField(field, value));
    };

    return (
        <BlockLoadGroup size="medium" isLoading={loading}>
            <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
                <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <div>
                            <Typography variant="h6">
                                {isDetailsMode ? 'Edit Private Deal' : 'New Deal'}
                            </Typography>
                        </div>
                        <div>
                            <IconButton onClick={onClose} size="large">
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Box pt={2}>
                        <Grid container direction="column" spacing={2}>
                            {fields.map(field => {
                                if (field.showOn && !field.showOn()) {
                                    return null;
                                }

                                return (
                                    <Grid item xs={12} direction="column" key={field.key}>
                                        <FormLabel
                                            required={field.required}
                                            classes={{ asterisk: classes.red }}
                                        >
                                            {field.label}
                                        </FormLabel>

                                        {field.getInput({
                                            classes,
                                            value: draft[field.key],
                                            onChange,
                                            error: errors[field.key],
                                            showErrors,
                                            draft,
                                            deal,
                                            isDetailsMode,
                                            environmentSettings,
                                            loading,
                                            advertiserOptions,
                                        })}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                </DialogContent>

                <DialogActions className={classes.dialogPadding}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => dispatch(actions.submitForm(isDetailsMode, deal.id))}
                    >
                        {' '}
                        SAVE{' '}
                    </Button>
                </DialogActions>
            </Dialog>
        </BlockLoadGroup>
    );
};

export default connect(selector)(DealModal);
