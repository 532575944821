import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

import { MenuSeparator } from 'widgets-v5/menu';
import DateTimeRangePicker from 'widgets/date-time-range-picker';
import { DialogTitle } from 'widgets-v6/modal';
import { SimpleSelect } from 'widgets-v6/select';
import { NEW_DIMENSIONS } from '../../../../campaigns/report/report-explore/v2/constants';

const useStyles = makeStyles({
    textFieldInput: {
        padding: 13,
        backgroundColor: 'white',
        borderRadius: 'inherit',
    },
});

const defaultFilter = {
    start: moment()
        .startOf('month')
        .format('YYYY-MM-DDTHH:mm:ss'),
    end: moment()
        .endOf('month')
        .format('YYYY-MM-DDTHH:mm:ss'),
    last: null, //Number
    lastFrame: null, // 'String',
    from: 'day_before_report_generation_date', // report_generation_date 'String',
    type: 'preset', // automatic, dynamic, custom, preset'
    campaignIds: [],
};

class DateRangeSelectorV2 extends React.Component {
    getLastNMonths = n => {
        const month = moment();
        const monthsBetween = [];
        const { timezone } = this.props;

        _.times(n, () => {
            monthsBetween.push({
                title: month.format('MMMM, YYYY'),
                start: month.startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                end: month.endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                isBlocked: this.hasNewDimensionsSelected() && month.endOf('month').isBefore('2023-04-01'),
                range: `${moment
                    .tz(month.startOf('month'), timezone)
                    .format('MMMM D, YYYY HH:mm z')} - ${moment
                    .tz(month.endOf('month'), timezone)
                    .format('MMMM D, YYYY HH:mm z')}`,
            });

            month.subtract(1, 'month');
        });

        return monthsBetween;
    };

    hasNewDimensionsSelected() {
        const { draft } = this.props;
        return draft.dimensions &&
            draft.dimensions.length &&
            draft.dimensions.some(dimension => NEW_DIMENSIONS.includes(dimension));
    }

    render() {
        const last3Months = this.getLastNMonths(3);

        const { timezone, draft, filterType, campaignOptions, engagementOptions, errors } = this.props;
        let filter = defaultFilter;

        if (filterType && draft[filterType]) {
            filter = draft[filterType];
        }

        let label;
        if (filter.type === 'automatic') {
            label = 'Automatic';
        } else {
            const startPrettyString = moment
                .tz(filter.start, timezone)
                .format('MMMM D, YYYY HH:mm z');
            const endPrettyString = moment.tz(filter.end, timezone).format('MMMM D, YYYY HH:mm z');
            label = `${startPrettyString} - ${endPrettyString}`;
        }

        const dateRangeOutOfRange =
            filter.start < '2020-01-01T00:00:00' || filter.end < '2020-01-01T00:00:00';

        const dynamicRangeNumbers = _.times(51, Number).slice(1);

        const dynamicRangeNumbersObj = dynamicRangeNumbers.map((v, i) => ({
            label: dynamicRangeNumbers[i],
            value: v,
        }));

        const dynamicRangePeriodOfTimeOptions = [
            {
                label: 'Hour(s)',
                value: 'hours',
            },
            {
                label: 'Day(s)',
                value: 'days',
            },
            {
                label: 'Week(s)',
                value: 'weeks',
            },
            {
                label: 'Month(s)',
                value: 'months',
            },
        ];

        return (
            <div>
                <RadioGroup
                    value={filter.type === 'dynamic' ? 'dynamic' : 'preset'}
                    onChange={e =>
                        this.props.updateFilterDateRangeMode({ filterType, type: e.target.value })
                    }
                    row
                >
                    <FormControlLabel
                        value="preset"
                        control={<Radio color="primary" />}
                        label="Fixed Range"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="dynamic"
                        control={<Radio color="primary" />}
                        label="Dynamic Range (useful for scheduled reports)"
                        labelPlacement="end"
                    />
                </RadioGroup>
                {filter.type === 'dynamic' ? (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>Last</Grid>
                        <Grid item>
                            <SimpleSelect
                                value={filter.last}
                                options={dynamicRangeNumbersObj}
                                error={errors && errors.dateRange}
                                onChange={e =>
                                    this.props.updateFilterLast({
                                        filterType,
                                        last: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item>
                            <SimpleSelect
                                value={filter.lastFrame}
                                options={dynamicRangePeriodOfTimeOptions}
                                error={errors && errors.dateRange}
                                onChange={e =>
                                    this.props.updateLastFrame({
                                        filterType,
                                        lastFrame: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item>from</Grid>
                        <Grid item>
                            <SimpleSelect
                                value={filter.from}
                                options={[
                                    {
                                        label: 'Day before report generation date',
                                        value: 'day_before_report_generation_date',
                                    },
                                    {
                                        label: 'Report generation date',
                                        value: 'report_generation_date',
                                    },
                                ]}
                                error={errors && errors.dateRange}
                                onChange={e => {
                                    this.props.updateFilterFrom({
                                        filterType,
                                        from: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Box mt={1}>
                        <DateRangeDropdown
                            draft={this.props.draft}
                            updateDateRange={this.props.updateDateRange}
                            filter={filter}
                            filterType={filterType}
                            updateCustomModeDateRange={this.props.updateCustomModeDateRange}
                            defaultValue={_.find(last3Months, { range: label })?.title}
                            hasNewDimensionsSelected={this.hasNewDimensionsSelected()}
                        >
                            {_.map(last3Months, month => (
                                    <MenuItem
                                        disabled={month.isBlocked}
                                        onClick={() => {
                                            this.props.updatePresetModeDateRange({
                                                filterType,
                                                month,
                                                campaignOptions,
                                                engagementOptions,
                                                type: 'preset',
                                            });
                                        }}
                                        key={month.title}
                                        value={month.title}
                                    >
                                        {month.title}
                                    </MenuItem>
                            ))}
                            <MenuSeparator />
                            {this.props.schemaVersion === '1' && (
                                <MenuItem
                                    onClick={() => {
                                        this.props.updateAutomaticModeDateRange({
                                            filterType,
                                            campaignOptions,
                                            engagementOptions,
                                            type: 'automatic',
                                        });
                                        close();
                                    }}
                                >
                                    Automatic
                                </MenuItem>
                            )}
                        </DateRangeDropdown>
                    </Box>
                )}
                {this.hasNewDimensionsSelected() && (
                    <Alert severity="warning">
                        Some dates may be disabled because of the selected dimensions.
                    </Alert>
                )}
                {errors && errors.dateRange && (
                    <Alert severity="error">
                        {errors.dateRange}
                    </Alert>
                )}
                {dateRangeOutOfRange && (
                    <Alert severity="error">
                        For reports before January 1, 2020, please contact us.
                    </Alert>
                )}
            </div>
        );
    }
}

function DateRangeDropdown(props) {
    const [open, setOpen] = React.useState(false);
    const [start, setStart] = React.useState(props.filter.start);
    const [end, setEnd] = React.useState(props.filter.end);
    const [value, setValue] = React.useState(props.defaultValue);
    const classes = useStyles();

    const handleChange = value => {
        setValue(value);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const setDate = dateRange => {
        if (!dateRange.start || !dateRange.end) {
            setStart(dateRange.start);
            setEnd(dateRange.end);
            return;
        }

        const start = moment
            .tz(dateRange.start, props.draft.timezone)
            .format('YYYY-MM-DDTHH:mm:ss');

        const end = moment.tz(dateRange.end, props.draft.timezone).format('YYYY-MM-DDTHH:mm:ss');

        setStart(start);
        setEnd(end);
    };

    const handleApply = () => {
        props.updateCustomModeDateRange({
            filterType: props.filterType,
            start: start,
            end: end,
        });
        handleClose();
    };

    return (
        <React.Fragment>
            <FormControl variant="outlined" fullWidth>
                <Select
                    classes={{ root: classes.textFieldInput }}
                    value={value || 'Custom Range'}
                    onChange={e => handleChange(e.target.value)}
                >
                    {props.children}
                    <MenuItem value={'Custom Range'} onClick={handleOpen}>
                        Custom Range
                    </MenuItem>
                </Select>
            </FormControl>
            <Dialog open={open}>
                <DialogTitle>Custom Range</DialogTitle>
                <DialogContent dividers>
                    <DateTimeRangePicker
                        key={start}
                        timezone={props.draft.timezone}
                        mode="inline"
                        value={{ start: start, end: end }}
                        onChange={setDate}
                        firstSelectableDate={props.hasNewDimensionsSelected ? moment('2023-04-01') : null}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleApply}
                        disabled={!start && !end}
                    >
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default DateRangeSelectorV2;
