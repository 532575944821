import React, { Fragment, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment-timezone';
import * as Diff from 'diff';
import parse from 'html-react-parser';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ClickAndCopyButton from 'widgets/click-and-copy-button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function getFormatDate(entryDate, organizationTimezone) {
    const dayMonthYear = moment(entryDate)
        .tz(organizationTimezone)
        .format('MMM DD, YYYY');
    const hoursMinutesSeconds = moment(entryDate)
        .tz(organizationTimezone)
        .format('HH:mm:ss');
    const timezoneAbbreviation = `(${moment()
        .tz(organizationTimezone)
        .format('z')})`;
    return dayMonthYear + ' ' + hoursMinutesSeconds + ' ' + timezoneAbbreviation;
}

function getUpdatedItem(fields, entry) {
    const updateType = _.capitalize(entry.action);
    let updatedItem = '';
    if (entry.action !== 'created') {
        _.map(fields, field => {
            if (field.hasChange(entry) && !field.isCategory) {
                updatedItem += ' ' + field.label + ',';
            }
        });
        updatedItem = updatedItem.replace(/,$/, '');
    }
    return updateType + updatedItem;
}

function getUpdatedBy(user) {
    const email = '(' + user.email + ')';
    return user.first_name + ' ' + user.last_name + '\n' + email;
}

function renderClickAndCopyButton(changedData, classes) {
    let textNoTags = changedData.curr;
    if (changedData.label.indexOf('HTML') === -1)
        textNoTags = textNoTags.replace(/(<([^>]+)>)/gi, '');
    return (
        <div className={classes.copyButton}>
            <ClickAndCopyButton textToCopy={textNoTags} textToDisplay="Copy" />
        </div>
    );
}

function setChangeStyle(value, change) {
    let returnString = value;
    if (change === 'added') {
        returnString = '<span style="background-color:#e0f2f1;">' + returnString + '</span>';
    } else if (change === 'removed') {
        returnString = '<span style="background-color:#f6bcbb;">' + returnString + '</span>';
    }
    return returnString;
}

function getPrevCurrentComparison(dataObj) {
    let returnObj = dataObj;
    if (returnObj.prev === undefined) return;
    let prev =
            typeof returnObj.prevState === 'object'
                ? renderToStaticMarkup(returnObj.prevState)
                : returnObj.prevState,
        curr =
            typeof returnObj.current === 'object'
                ? renderToStaticMarkup(returnObj.current)
                : returnObj.current;

    let diff,
        updatedValue = '',
        isDiv = false;
    if (/<[a-z][\s\S]*>/i.test(curr)) {
        diff = Diff.diffTrimmedLines(prev, curr);
        isDiv = true;
    } else {
        diff = Diff.diffWords(prev, curr);
    }

    for (let i = 0; i < diff.length; i++) {
        const wordHasNoChange = !diff[i]['removed'] && !diff[i]['added'];
        const hasWordBeenAdded = diff[i]['added'];
        const hasWordBeenRemoved = diff[i]['removed'];

        if (hasWordBeenAdded) {
            diff[i]['currExist'] = true;
        }

        if (hasWordBeenRemoved) {
            diff[i]['prevExist'] = true;
        }

        if (wordHasNoChange) {
            diff[i]['currExist'] = true;
            diff[i]['prevExist'] = true;
        }
    }

    if (!isDiv) {
        let currentTemp = '',
            prevTemp = '';
        for (let j = 0; j < diff.length; j++) {
            if (diff[j]['prevExist'] && diff[j]['currExist']) {
                prevTemp += diff[j]['value'];
                currentTemp += diff[j]['value'];
            }
            if (diff[j]['prevExist'] && !diff[j]['currExist']) {
                prevTemp += diff[j]['added']
                    ? setChangeStyle(diff[j]['value'], 'added')
                    : diff[j]['removed']
                    ? setChangeStyle(diff[j]['value'], 'removed')
                    : diff[j]['value'];
            }
            if (diff[j]['currExist'] && !diff[j]['prevExist']) {
                currentTemp += diff[j]['added']
                    ? setChangeStyle(diff[j]['value'], 'added')
                    : diff[j]['removed']
                    ? setChangeStyle(diff[j]['value'], 'removed')
                    : diff[j]['value'];
            }
        }
        returnObj.prevState = prevTemp;
        returnObj.current = currentTemp;
    } else {
        diff.forEach(function(str) {
            if (str.added) {
                updatedValue = '<div style="background-color:#e0f2f1;">' + str.value + '</div>';
                if (curr.indexOf(str.value) !== -1) {
                    returnObj.current = curr.replace(str.value, updatedValue);
                } else if (prev.indexOf(str.value) !== -1) {
                    returnObj.prevState = prev.replace(str.value, updatedValue);
                }
            } else if (str.removed) {
                updatedValue = '<div style="background-color:#f6bcbb;">' + str.value + '</div>';
                if (curr.indexOf(str.value) !== -1) {
                    returnObj.current = curr.replace(str.value, updatedValue);
                } else if (prev.indexOf(str.value) !== -1) {
                    returnObj.prevState = prev.replace(str.value, updatedValue);
                }
            }
        });
    }
    return returnObj;
}

export function renderTableRow(
    fields,
    historyEntries,
    selectedRow,
    classes,
    isFold,
    isProspectMedia = false
) {
    const [mouseOnRow, setMouseOnRow] = useState(null);
    let changedData = [],
        dataObj = {},
        isPrevCompare = false;

    _.map(fields, field => {
        if (historyEntries[selectedRow] === undefined) return;
        dataObj.isCategory = field.isCategory;
        if (isFold) {
            // show only changes
            if (field.hasChange(historyEntries[selectedRow])) {
                dataObj.label = field.label;
                if (selectedRow + 1 < historyEntries.length) {
                    dataObj.prevState = field.format(historyEntries[selectedRow + 1]);
                    dataObj.prevState =
                        typeof dataObj.prevState === 'object'
                            ? renderToStaticMarkup(dataObj.prevState)
                            : typeof dataObj.prevState === 'number'
                            ? dataObj.prevState.toString()
                            : dataObj.prevState;
                    if (Array.isArray(dataObj.prevState)) {
                        dataObj.prevState = dataObj.prevState.join(', ');
                    }
                    isPrevCompare = true;
                }
                dataObj.current = field.format(historyEntries[selectedRow]);
                dataObj.current =
                    typeof dataObj.current === 'object'
                        ? renderToStaticMarkup(dataObj.current)
                        : typeof dataObj.current === 'number'
                        ? dataObj.current.toString()
                        : dataObj.current;

                if (Array.isArray(dataObj.current)) dataObj.current = dataObj.current.join(', ');

                dataObj.prev =
                    typeof dataObj.prevState === 'object'
                        ? renderToStaticMarkup(dataObj.prevState)
                        : typeof dataObj.prevState === 'number'
                        ? dataObj.prevState.toString()
                        : dataObj.prevState;

                dataObj.curr =
                    typeof dataObj.current === 'object'
                        ? renderToStaticMarkup(dataObj.current)
                        : typeof dataObj.current === 'number'
                        ? dataObj.current.toString()
                        : dataObj.current;

                if (isPrevCompare) dataObj = getPrevCurrentComparison(dataObj);
                if (dataObj && (dataObj.curr !== dataObj.prev || dataObj.isCategory)) {
                    changedData.push(dataObj);
                }
                dataObj = {};
            }
        } else {
            // show history snapshot
            dataObj.label = field.label;
            let formatData =
                typeof field.format(historyEntries[selectedRow]) === 'object'
                    ? renderToStaticMarkup(field.format(historyEntries[selectedRow]))
                    : typeof field.format(historyEntries[selectedRow]) === 'number'
                    ? field.format(historyEntries[selectedRow]).toString()
                    : field.format(historyEntries[selectedRow]);
            if (field.hasChange(historyEntries[selectedRow])) {
                let formatStr = formatData.replace(/<[^>]*>/g, '');
                dataObj.current = formatData.replace(
                    formatStr,
                    '<span style="background-color:#e0f2f1;">' + formatStr + '</span>'
                );
            } else {
                if (Array.isArray(formatData)) {
                    formatData = formatData.join(', ');
                }
                dataObj.current = formatData;
            }
            changedData.push(dataObj);
            dataObj = {};
        }
    });

    return (
        <Fragment>
            {_.map(changedData, function(changedData, index) {
                let currentData =
                    typeof changedData.current === 'object'
                        ? renderToStaticMarkup(changedData.current)
                        : changedData.current;

                const shouldNotRenderRow =
                    isProspectMedia &&
                    (changedData.label === 'Brand Safety' ||
                        changedData.label === 'Fraud Prevention');
                return (
                    !shouldNotRenderRow && (
                        <tr className={classes.tableTr} key={index}>
                            {changedData.isCategory ? (
                                <td className={classes.tableTdLeftCategorized}>
                                    {changedData.label}
                                </td>
                            ) : (
                                <td className={classes.tableTdLeft}>{changedData.label}</td>
                            )}
                            {isPrevCompare && !changedData.isCategory ? (
                                <td className={classes.tableTdRight}>
                                    <div className={classes.fromDiv}>
                                        <div className={classes.fromToLabel}>From:</div>
                                        <div className={classes.changed}>
                                            {changedData.label.indexOf('HTML') === -1
                                                ? parse(changedData.prevState)
                                                : changedData.prevState}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.toDiv}
                                        onMouseOver={() => {
                                            setMouseOnRow(index);
                                        }}
                                        onMouseLeave={() => {
                                            setMouseOnRow(null);
                                        }}
                                    >
                                        <div className={classes.fromToLabel}>To:</div>
                                        <div className={classes.current}>
                                            {changedData.label.indexOf('HTML') === -1
                                                ? parse(currentData)
                                                : currentData}
                                        </div>
                                        {mouseOnRow === index &&
                                            renderClickAndCopyButton(changedData, classes)}
                                    </div>
                                </td>
                            ) : isFold && !changedData.isCategory ? (
                                <td className={classes.tableTdRightCreated}>
                                    <span>{parse(currentData)}</span>
                                    {mouseOnRow === index &&
                                        renderClickAndCopyButton(changedData, classes)}
                                </td>
                            ) : (
                                <td className={classes.tableTdRight}>
                                    {currentData !== undefined && parse(currentData)}
                                    {currentData !== undefined &&
                                        currentData !== '' &&
                                        (mouseOnRow === index &&
                                            renderClickAndCopyButton(changedData, classes))}
                                </td>
                            )}
                        </tr>
                    )
                );
            })}
        </Fragment>
    );
}

const useStyles = makeStyles(() => ({
    historyTable: {
        height: 600,
    },
    tableTitle: {
        fontSize: 20,
    },
    titleCreativeId: {
        fontSize: 14,
    },
    cardWrapper: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    leftMenu: {
        overflowY: 'scroll',
        maxHeight: '418px',
        flex: '0 1 auto',
    },
    rightContents: {
        padding: '0 10px',
        overflow: 'auto',
        width: '100%',
    },
    eachEntry: {
        minHeight: 95,
        padding: 16,
        fontSize: 14,
        borderBottom: '1pt solid #E0E0E0',
        '&:hover': {
            backgroundColor: '#F2F2F2',
            cursor: 'pointer',
        },
    },
    entrySelected: {
        padding: 16,
        borderLeft: 'solid 6px #009688',
        backgroundColor: '#F2F2F2',
    },
    entryLI: {
        lineHeight: 1.3,
    },
    entryDate: {
        fontWeight: 500,
    },
    table: {
        width: '100%',

        '& #ad #container': {
            position: 'relative',
            margin: 0,
        },
    },
    tBody: {
        display: 'block',
        height: 418,
        overflowY: 'scroll',
        overflowX: 'hidden',
    },
    tableTr: {
        borderBottom: '1pt solid #E0E0E0',
        verticalAlign: 'middle',
    },
    tableTdLeftCategorized: {
        width: 190,
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'left',
        paddingLeft: 4,
        paddingTop: 15,
        paddingBottom: 15,
        wordBreak: 'break-all',
    },
    tableTdLeft: {
        width: 190,
        fontSize: 14,
        textAlign: 'left',
        paddingLeft: 4,
        paddingTop: 15,
        paddingBottom: 15,
        wordBreak: 'break-all',
    },
    tableTdRight: {
        width: '79%',
        fontSize: 14,
        verticalAlign: 'middle',
        textAlign: 'left',
        paddingTop: 12,
        paddingRight: 8,
        paddingBottom: 8,
        lineHeight: '17px',
        wordBreak: 'break-all',
    },
    tableTdRightCreated: {
        width: '79%',
        fontSize: 14,
        verticalAlign: 'middle',
        textAlign: 'left',
        paddingTop: 12,
        paddingRight: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        lineHeight: '17px',
        wordBreak: 'break-all',
        backgroundColor: '#e0f2f1',
    },
    fromDiv: {
        display: 'block',
        minHeight: 24,
    },
    toDiv: {
        display: 'block',
        minHeight: 24,
        clear: 'both',
    },
    fromToLabel: {
        textAlign: 'right',
        paddingTop: 1,
        paddingRight: 8,
        float: 'left',
        fontWeight: 500,
        fontSize: 12,
        width: 45,
    },
    changed: {
        paddingBottom: 8,
        width: '82%',
        float: 'left',
    },
    current: {
        paddingBottom: 8,
        width: '82%',
        float: 'left',
    },
    tableToggleSwitch: {
        float: 'right',
        paddingRight: 20,
    },
    copyButton: {
        float: 'right',
        color: '#fff',
        backgroundColor: '#4FC3F7',
        border: '1px solid #4FC3F7',
        cursor: 'pointer',
        borderRadius: 2,
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 170,
        color: '#009688',
    },
}));

function getTopLevelFields(patch) {
    return _.map(patch, (value, key) => {
        const parts = key.split('.');

        return parts[0];
    });
}

export function hasTopLevelFieldsBeenModified(patch, fieldsToCompare) {
    const topLevelFields = getTopLevelFields(patch);

    const intersection = _.intersection(topLevelFields, fieldsToCompare);

    const hasChange = intersection.length > 0;

    return hasChange;
}

export function HistoryTableV3(props) {
    const {
        title,
        secondaryTitle,
        historyEntries,
        fields,
        organizationTimezone,
        organizationId,
        isLoading,
    } = props;
    const [selectedRow, setSelectedRow] = useState(0);
    const [isFold, setIsFold] = useState(true);
    const classes = useStyles();
    const indexCreatedRow = historyEntries.length - 1;

    const SnapshotSwitch = withStyles({
        switchBase: {
            color: '#FAFAFC',
            '&$checked': {
                color: '#009688',
            },
            '&$checked + $track': {
                backgroundColor: '#009688',
            },
        },
        checked: {},
        track: {},
    })(Switch);

    const isProspectMedia = organizationId === '61ba37fb834aef8e532e0687';
    const tableRows = (
            <div className={classes.rightContents}>
                <table className={classes.table}>
                    <tbody className={classes.tBody}>
                        {renderTableRow(
                            fields,
                            historyEntries,
                            selectedRow,
                            classes,
                            isFold,
                            isProspectMedia
                        )}
                    </tbody>
                </table>
            </div>
        );

    return (
        <div>
            <Box margin="20px 0" className={classes.tableTitle}>{title}</Box>
            <Box marginBottom="10px" display="flex" alignItems="center" justifyContent="space-between">
                <div>
                    <span className={classes.titleCreativeId}>{secondaryTitle}</span>
                </div>
                <div className={classes.tableToggleSwitch}>
                    {indexCreatedRow !== selectedRow && (
                        <FormControlLabel
                            control={
                                <SnapshotSwitch checked={!isFold} onChange={() => setIsFold(!isFold)} />
                            }
                            label="Show history snapshots"
                            labelPlacement="start"
                        />
                    )}
                </div>
            </Box>
            <Card className={classes.cardWrapper}>
                {isLoading && (
                    <div className={classes.progress}>
                        <CircularProgress color='inherit' />
                    </div>
                )}
                {!isLoading && historyEntries.length === 0 && (
                    <div className={classes.progress}>
                        <Typography align='center'>No history found</Typography>
                    </div>
                )}
                {!isLoading && historyEntries.length !== 0 && <Box display="flex" alignItems="start">
                    <div className={classes.leftMenu}>
                        {_.map(historyEntries, (entry, index) => {
                            return (
                                <ul
                                    key={index}
                                    className={classnames(
                                        classes.eachEntry,
                                        selectedRow === index ? classes.entrySelected : ''
                                    )}
                                    onClick={() => {
                                        setSelectedRow(index);
                                        setIsFold(true);
                                    }}
                                >
                                    <li className={classnames(classes.entryLI, classes.entryDate)}>
                                        {getFormatDate(entry.date, organizationTimezone)}
                                    </li>
                                    <li className={classes.entryLI}>{getUpdatedItem(fields, entry)}</li>
                                    <li className={classes.entryLI}>By {getUpdatedBy(entry.user)}</li>
                                </ul>
                            );
                        })}
                    </div>
                    {tableRows}
                </Box>}
            </Card>
        </div>
    );
}
