import _ from 'lodash';

export default function selector(storeState, props) {
    const { loading, deals, keywords, sort, timezone, filters, advertisers } = _.get(
        storeState,
        'dealsTable'
    );

    const advertiserOptions = _.map(advertisers, advertiser => {
        return { label: advertiser.name, value: advertiser.id };
    });

    return {
        loading,
        deals,
        keywords,
        sort,
        timezone,
        filters,
        advertiserOptions,
    };
}
