import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import flags from 'containers/flags/service';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import { browserHistory } from 'react-router';

const AppsAndSitesLanding = props => {
    const [currentTab, setCurrentTab] = useState(0);
    const tabs = [
        {
            label: 'Apps & Sites',
            value: 'appsAndSites',
            link: '/apps-and-sites',
        },
    ];

    tabs.push({
        label: 'Apps Report',
        value: 'appsReport',
        link: '/apps-and-sites/report',
    });

    tabs.push({
        label: 'Sites Report',
        value: 'sitesReport',
        link: '/apps-and-sites/site-report',
    });

    tabs.push({
        label: 'Private Deals',
        value: 'dealsInventory',
        link: '/apps-and-sites/inventory',
    });

    useEffect(() => {
        let tab;
        switch (location.pathname) {
            case '/apps-and-sites':
                tab = 'appsAndSites';
                break;
            case '/apps-and-sites/report':
                tab = 'appsReport';
                break;
            case '/apps-and-sites/site-report':
                tab = 'sitesReport';
                break;
            case '/apps-and-sites/inventory':
                tab = 'dealsInventory';
                break;
        }
        setCurrentTab(tab);
    }, []);

    const onChange = (event, value) => {
        setCurrentTab(value);
        const tab = tabs.find(tab => tab.value === value);
        browserHistory.push(tab.link);
    };

    return (
        <div>
            <AppBar position="static" color="default" elevation={1}>
                <Tabs
                    value={currentTab}
                    onChange={onChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {tabs.map(tab => (
                        <Tab key={tab.value} label={tab.label} value={tab.value} />
                    ))}
                </Tabs>
            </AppBar>
            {props.workspace}
        </div>
    );
};

export default AppsAndSitesLanding;
