import find from 'lodash/find';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import React from 'react';
/** import third-party libraries in the section above, keep the ascending order */

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
/** import mui components in the section above, keep the ascending order */

import { Label } from 'widgets-v5/typography';
import AccordionItem from 'widgets-v5/expansion-panel-item';
import Spacer from 'widgets-v5/spacer';
/** import widgets-v5 components in the section above, keep the ascending order */

import { IncludeExcludeLayout } from 'widgets-v6/layout';
import { MultiSelect, GroupedMultiSelect } from 'widgets-v6/select';
/** import widgets-v6 components in the section above, keep the ascending order */

import { ApplistSelector, ApplistSummary } from '../target-applists';
import { formatNumber_currency } from 'utils/formatting';
import { getAllowedPlatformOptions } from 'states/resources/ads/business-logic';
import { getExchangeOptions } from 'states/resources/ads/business-logic';
import { getExchangesBlocked, getApplists } from 'containers/ad-summary';
import { useAdForm } from 'forms/ad-form/v2/hook';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
/** import classes and others in the section above, keep the ascending order */

const useStyles = makeStyles(() => ({
    appListError: {
        marginTop: 10,
        marginLeft: 30,
        color: 'red',
    },
}));

export default function AdFormInventory({
    expandPanel,
    expanded,
    formData,
    dealsByOrganization,
    adForm,
    onFieldChange,
    isCrossPlatformCampaign,
    showPlatformRestrictedFields,
    environmentSettings,
    errors,
    showErrors,
    applists,
    campaign,
    organizationName,
    advertiser,
    exchangeWarning,
}) {
    const classes = useStyles();
    const { dealsWithConvertedPrice } = useAdForm();
    const ALLOWED_PLATFORMS = getAllowedPlatformOptions(campaign.type);

    const handleExchangeExcludeChange = value => {
        const parsedValues = value.map(currentExchange =>
            ALLOWED_PLATFORMS.map(platform => `${currentExchange}_${platform}`)
        );
        onFieldChange('exchanges_blocked_ui')(flatten(value));
        onFieldChange('exchanges_blocked')(flatten(parsedValues));
    };

    const setDealApplicationType = e => {
        const dealApplicationType = e.target.checked ? 'deal_augmented' : 'deal_only';
        onFieldChange('dealApplicationType')(dealApplicationType);
    };

    const showPrices = deal => {
        const campainCurrency = campaign.currency;
        const dealCurrency = deal.currency;

        if (campainCurrency !== dealCurrency) {
            return `Price: ${dealCurrency} ${formatNumber_currency(
                deal.price_local
            )} (approx. ${campainCurrency} ${formatNumber_currency(deal.convertedPrice)})`;
        }

        return `Price: ${dealCurrency} ${formatNumber_currency(deal.price_local)}`;
    };

    const getDealsOptions = () => {
        return map(dealsWithConvertedPrice, deal => {
            const isCampaignAdvertiser =
                advertiser && deal.advertiser && advertiser.id === deal.advertiser.id;
            return {
                label: deal.app_name,
                key: deal.id,
                value: deal.id,
                description: showPrices(deal),
                group: `List for ${isCampaignAdvertiser ? deal.advertiser.name : organizationName}`,
            };
        });
    };

    const getAppsAndSitesOptions = () => {
        return map(applists, applist => {
            const isCampaignAdvertiser =
                advertiser && applist.advertiser && advertiser.id === applist.advertiser.id;
            return {
                key: applist.id,
                value: applist.id,
                label: applist.name,
                group: `List for ${
                    isCampaignAdvertiser ? applist.advertiser.name : organizationName
                }`,
            };
        });
    };

    return (
        <React.Fragment>
            <Typography>Inventory</Typography>
            <Spacer />
            <AccordionItem
                onClick={expandPanel('public_inventory')}
                expanded={expanded === 'public_inventory'}
                title="Public Inventory"
                details={
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.dealApplicationType === 'deal_augmented'}
                                    onChange={setDealApplicationType}
                                    value="dealApplicationType"
                                    color="primary"
                                />
                            }
                            label="Bid on open auctions"
                        />
                    </div>
                }
                summary={
                    <div>
                        <Label>
                            {formData.dealApplicationType === 'deal_augmented'
                                ? 'Bid on open auctions'
                                : 'Do not bid on open auctions'}
                        </Label>
                    </div>
                }
            />
            <AccordionItem
                onClick={expandPanel('deals')}
                expanded={expanded === 'deals'}
                title="Private Deals"
                error={showErrors && errors.deals}
                details={
                    <div>
                        {' '}
                        <GroupedMultiSelect
                            value={formData.deals}
                            onChange={onFieldChange('deals')}
                            options={getDealsOptions()}
                            placeholder="Select private deals"
                        />
                    </div>
                }
                summary={
                    formData.deals.length > 0 ? (
                        <Label>
                            {_(formData.deals)
                                .map(dealId =>
                                    find(dealsByOrganization, {
                                        id: dealId,
                                    })
                                )
                                .filter(deal => deal)
                                .map(deal => deal.app_name)
                                .join(', ')}
                        </Label>
                    ) : (
                        <Label placeholder>Select a private deal</Label>
                    )
                }
            />
            {adForm.campaign.isExchangeTargetingEnabled && environmentSettings.canUseExchanges() && (
                <AccordionItem
                    onClick={expandPanel('exchangesBlocked')}
                    expanded={expanded === 'exchangesBlocked'}
                    disabled={isCrossPlatformCampaign ? false : !showPlatformRestrictedFields}
                    title="Exchanges"
                    details={
                        <React.Fragment>
                            <IncludeExcludeLayout type="Include" marginBottom>
                                {' '}
                                {/*margin Bottom to check*/}
                                All compatible and new exchanges
                            </IncludeExcludeLayout>
                            <IncludeExcludeLayout type="Exclude">
                                <MultiSelect
                                    value={formData.exchanges_blocked_ui}
                                    onChange={handleExchangeExcludeChange}
                                    options={getExchangeOptions(adForm.campaign)}
                                    placeholder="Select exchanges to exclude"
                                />
                            </IncludeExcludeLayout>
                            {isCrossPlatformCampaign && exchangeWarning.hasWarning > 0 && (
                                <Box marginTop={1} marginLeft={4}>
                                    <WarningText
                                        message={exchangeWarning.message}
                                        severity={exchangeWarning.severity}
                                    />
                                </Box>
                            )}
                        </React.Fragment>
                    }
                    summary={
                        <React.Fragment>
                            <IncludeExcludeLayout type="Include" marginBottom>
                                All compatible and new exchanges
                            </IncludeExcludeLayout>
                            <IncludeExcludeLayout type="Exclude">
                                {getExchangesBlocked(formData.exchanges_blocked_ui)}
                            </IncludeExcludeLayout>
                        </React.Fragment>
                    }
                />
            )}
            <AccordionItem
                onClick={expandPanel('applists')}
                expanded={expanded === 'applists'}
                title={environmentSettings.getAppsAndSitesLabel()}
                error={errors.blacklistedAppsAndSites}
                details={
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <ApplistSelector
                                type="Include"
                                label="Allowed"
                                placeholder={environmentSettings.getAppsAndSitesPlaceholder()}
                                onChange={values =>
                                    onFieldChange('whitelistedAppsAndSites')(values)
                                }
                                values={formData.whitelistedAppsAndSites}
                                options={getAppsAndSitesOptions()}
                            />
                        </Grid>
                        <Grid item>
                            <ApplistSelector
                                type="Exclude"
                                placeholder="None"
                                label="Blocked"
                                onChange={values =>
                                    onFieldChange('blacklistedAppsAndSites')(values)
                                }
                                values={formData.blacklistedAppsAndSites}
                                options={getAppsAndSitesOptions()}
                            />
                        </Grid>
                        {errors.blacklistedAppsAndSites && (
                            <Grid item>
                                <Typography
                                    classes={{
                                        root: classes.appListError,
                                    }}
                                >
                                    {errors.blacklistedAppsAndSites}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                }
                summary={
                    <ApplistSummary
                        allowedList={getApplists(formData.whitelistedAppsAndSites, applists)}
                        blockedList={getApplists(formData.blacklistedAppsAndSites, applists)}
                    />
                }
            />
        </React.Fragment>
    );
}
