import _ from 'lodash';

import { isAdUsingDisplayCreative } from 'states/resources/ads/business-logic';
import {
    getAssignedPacing,
    getTotalRotationWeight,
    isPacingColumnEnabledForRotationSelector,
    processCreative,
} from 'states/resources/creatives/business-logic';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

export default function selector(storeState, props) {
    const { campaignId, adId } = props;

    const propsOut = {
        adId: void 0,
        campaignId: void 0,
        formData: {},
        errors: {},
        showErrors: false,
        isDraftValid: false,
    };

    const adForm = _.get(storeState, `adForm.form`);

    const profileToken = _.get(storeState, `profile.authToken`);
    const profileRole = _.get(storeState, `profile.globalRole`);

    const campaignById = _.get(storeState, `resources.campaigns.${campaignId}`);
    const creativeIdsByCampaign = _.get(campaignById, `attr.creatives`);
    const campaignsInResources = _.get(storeState, `resources.creatives`, []);
    const creativesByCampaign = _.pick(campaignsInResources, creativeIdsByCampaign);

    const {
        // This is used to signal that the user can't modify the billing term or the billing rate.
        // These are added to the form draft when it is first initialized.
        // (see `pages/ad-form/ad-form-new/actions.js`)
        campaign_budget_enabled: campaignBudgetEnabled,
        billing_term: campaignBillingTerm,
        billing_rate: campaignBillingRate,
        isIasEnabled,
    } = campaignById.attr;

    const showFrequencyCapWarning = _.get(adForm, `showFrequencyCapWarning`);
    const shouldShowFrequencyCapWarningOnChange = _.get(
        adForm,
        `shouldShowFrequencyCapWarningOnChange`
    );
    const errorsAsList = _.get(adForm, `errors`, []);
    const draft = _.get(adForm, `draft`, {});
    const startNow = _.get(adForm, `startNow`, {});
    const scheduledRotationWarningEnabled = _.get(adForm, `scheduledRotationWarningEnabled`, false);
    const mode = _.get(adForm, `mode`, []);

    const formData = getFormData(draft);
    const formDataLoaded = !!formData;

    const rotationMode = formDataLoaded ? formData.rotation_rules.mode : '';
    const primaryPacing = formDataLoaded && formData.primary_pacing;
    let totalRotationWeight = getTotalRotationWeight(formDataLoaded, formData);

    let assignedPacing = getAssignedPacing(primaryPacing, formData, totalRotationWeight);

    // const geoResources = _.get(storeState, `resources.geoCategories`);
    const geoResources_unfiltered = _.get(storeState, `resources.geoCategories`);
    const geoCategories_session = _.get(storeState, `adForm.form.geoCategories`, void 0);

    const currency = _.get(storeState, `resources.campaigns.${campaignId}.attr.currency`, 'USD');
    const campaignBillingEnabled = _.get(adForm, 'campaignBillingEnabled');

    let geoCategories = {
        ...geoCategories_session,
        geoResources_unfiltered: {
            ...geoResources_unfiltered,
        },
        selectedCategories: [],
        selectedCategoryLayers: [],
        selectedCustomLayers: [],
        unassignedPoints: [],
    };

    const poiViewer = _.get(storeState, `adForm.poiViewer`, void 0);
    const geofencingEntityInView = _.get(poiViewer, `currentView`, void 0);
    const poiViewer_eTag = _.get(poiViewer, `eTag`, void 0);

    if (formData) {
        const { geo_targeting_settings } = formData;

        const { categories, custom_layers, category_layers } = geo_targeting_settings;

        const layerMapping = getLayerMapping(geoResources_unfiltered.geoLayers);

        const selectedCategories = _.reduce(
            geoResources_unfiltered.geoCategories,
            (acc, category) => {
                const selectedSubs = _.filter(category.subcategories, sub =>
                    _.includes(categories, sub.id)
                );

                return [...acc, ...selectedSubs];
            },
            []
        );

        const selectedCustomLayers = _.filter(
            geoResources_unfiltered.geoLayers,
            layer => _.includes(custom_layers, layer.id) && !_.includes(layer.name, '___implicit')
        );

        const selectedCategoryLayers = lookupCategoryLayers({
            layerIds: category_layers,
            layerMapping,
        });

        //@TODO following is alex' code, check that if not use then remove it
        const unassignedPoints = _.get(storeState, `adForm.form.unassignedPoints`, []);

        const implicitLayer = getImplicitLayer(storeState, campaignId);
        const id_implicitLayer = _.get(implicitLayer, 'id', void 0);

        geoCategories = {
            ...geoCategories_session,
            geoResources_unfiltered: {
                ...geoResources_unfiltered,
            },
            selectedCategories,
            selectedCategoryLayers,
            selectedCustomLayers,
            unassignedPoints, //@TODO to remove
            id_implicitLayer,
            implicitLayer,
        };
    }

    const geoLayerSearchString = _.get(storeState, `adForm.form.searchString`, '');

    const isAdPresetModalOpen = _.get(storeState, `adForm.form.isAdPresetModalOpen`, false);
    const adPresets = _.get(storeState, `resources.adPresets`);
    const isDelivering = _.get(storeState, 'adForm.form.isDelivering');
    const isStale = _.get(storeState, 'adForm.form.isStale');
    const errorMapping = _.get(storeState, `adForm.form.errorMapping`, {});
    const isUnalteredDuplicate = _.get(storeState, `adForm.form.draft.unalteredDuplicate`, false);

    let inventoryPredictionLoading = _.get(storeState, `adForm.form.inventoryPredictionLoading`);
    let inventoryPredictionError = _.get(storeState, `adForm.form.inventoryPredictionError`);

    const techFee = _.get(storeState, 'adForm.form.techFee');

    const shouldShowStartASAP = !adId || isUnalteredDuplicate;

    const errors = errorMapping;

    const ftaLocationListsMapping = {};
    _.each(adForm.ftaLocationLists, locationList => {
        ftaLocationListsMapping[locationList.id] = locationList;
    });

    const shouldUseIasPostBidVerification =
        isAdUsingDisplayCreative(formData, adForm.creatives) &&
        adForm.ownOrganization.isIasPostBidVerificationEnabled;

    const isCrossPlatformCampaign = adForm.isCrossPlatformCampaign;

    const adFormSetupWarnings = adForm.warnings;

    const o = {
        ...propsOut,

        adId,
        formData,
        currency: currency,
        campaignId,
        campaignBillingEnabled,
        profileToken,
        profileRole,

        campaignBudgetEnabled,
        campaignBillingTerm,
        campaignBillingRate,
        isIasEnabled,

        creativesByCampaign,
        creativesForCreativeSelector: processCreativesForRotationSelector({
            creativesByCampaign,
            formData,
            isCrossPlatformCampaign,
            campaign: campaignById.attr,
        }),
        rotationMode,
        scheduledRotationWarningEnabled,
        assignedPacing,
        totalRotationWeight,
        pacingColumnEnabled:
            !_.includes(
                [CreativeRotationMethodMapping.OptimizedForClicks,
                 CreativeRotationMethodMapping.OptimizedForConversions,
                 CreativeRotationMethodMapping.OptimizedForVideoCompletion,
                 CreativeRotationMethodMapping.Weather],
                rotationMode
            ) && isPacingColumnEnabledForRotationSelector(formData),
        geoCategories,
        geoLayerSearchString,
        geofencingEntityInView,
        poiViewer_eTag,

        adPresets,
        isAdPresetModalOpen,

        campaignById,
        startNow,

        isSubmitting: adForm.isSubmitting,
        isDraftValid: _.get(adForm, `isDraftValid`, false),
        showErrors: _.get(adForm, `showErrors`, false),
        errors,
        errorsByField: getErrorsByField(errorsAsList),

        inventoryPredictionLoading,
        inventoryPredictionError,
        isDelivering,
        isStale,
        adForm,
        mode,

        showFrequencyCapWarning,
        shouldShowFrequencyCapWarningOnChange,
        techFee,
        visiblePanel: adForm.visiblePanel,
        applists: adForm.applists,
        defaultBlacklists: adForm.defaultBlacklists,
        shouldShowStartASAP,
        dealsByOrganization: adForm.dealsByOrganization,
        ftaLocationListsMapping,
        ownOrganization: adForm.ownOrganization,
        campaignCarriersISPsVersion: adForm.campaignCarriersISPsVersion,
        conversions: adForm.conversions,
        isCrossPlatformCampaign,
        shouldUseIasPostBidVerification,
        adFormSetupWarnings,
        advertiser: _.get(storeState, `adForm.form.advertiser`),
        orgId: _.get(storeState, `profile.organizationId`),
    };
    return o;

    function getFormData(draft) {
        const formData = _.assign({}, draft);
        const out = Object.keys(formData).length === 0 ? null : formData;
        return out;
    }
}

function getImplicitLayer(storeState) {
    const geoLayers_super = _.get(storeState, `resources.geoCategories.geoLayers`, void 0);
    const form = _.get(storeState, `adForm.form`, {});
    const customLayers_draft = _.get(form, 'draft.geo_targeting_settings.custom_layers', void 0);

    const implicitLayer = _(geoLayers_super)
        .filter(itm => {
            return itm.name === '___implicit' && _.includes(customLayers_draft, itm.id);
        })
        .value()[0];

    return implicitLayer;
}

function processCreativesForRotationSelector({
    creativesByCampaign,
    formData,
    isCrossPlatformCampaign,
    campaign,
}) {
    if (!formData) {
        return {};
    }

    return _(creativesByCampaign)
        .map((creative, creativeId) => {
            const attr = creative.attr;
            return processCreative(attr, formData, creativeId, campaign);
        })
        .filter(creative => {
            if (creative.isArchived) {
                return false;
            } else {
                return true;
            }
        })
        .filter(creative => {
            if (isCrossPlatformCampaign) {
                return true;
            } else {
                return _.includes(creative.platforms, formData.platform);
            }
        })
        .value();
}

function getLayerMapping(geoLayers) {
    const mapping = {};
    _.each(geoLayers, layer => {
        mapping[layer.id] = layer;
    });

    return mapping;
}

function lookupCategoryLayers({ layerIds, layerMapping }) {
    return _.map(layerIds, id => layerMapping[id]).filter(x => x);
}

function getErrorsByField(errorsAsList) {
    const mapping = {};

    _.each(errorsAsList, error => {
        mapping[error.field] = error.message;
    });

    return mapping;
}
