import _ from 'lodash';
import toastr from 'toastr';

import { getDimensions } from 'common/constants/dimensions';
import { makeActions } from 'utils/redux-tools';
import { isDimensionShared } from 'containers/campaign-sharing/helper';
import flags from 'containers/flags/service';
import { getEnvironmentSettings } from 'services/environment';
import { RevenueModelMapping } from 'states/resources/campaigns/business-logic';

const TOASTR_OPTIONS = {
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

export const REDUCER_KEY = 'reportExploreV2';
export const initialState = {
    isLoading: false,
    sortColumn: 'dimension',
    sortDirection: 'asc',
    metrics: [],
    dimensions: [
        { name: 'age', label: 'Age' },
        { name: 'date', label: 'Date' },
        { name: 'day_of_week', label: 'Day Of Week' },
        { name: 'device_os', label: 'Device OS' },
        { name: 'gender', label: 'Gender' },
        { name: 'geo_country', label: 'Country' },
        { name: 'geo_country_region', label: 'Region' },
        { name: 'markup_id', label: 'Creative' },
        { name: 'audience_segment', label: 'Audience Segment' },
        { name: 'hour', label: 'Hour' },
        { name: 'month', label: 'Month' },
        {
            name: 'week',
            label: 'Week',
            children: [
                { name: 'week_by_monday_to_sunday', label: 'Week (Monday to Sunday)' },
                { name: 'week_by_campaign_start_day', label: 'Week (every 7 days)' },
            ],
        },
        { name: 'ad_id', label: 'Ad' },
        { name: 'adgroup', label: 'Custom Dimensions', children: [] },
        { name: 'tactic_id', label: 'Tactic' },
        { name: 'platform', label: 'Platform' },
    ],
    selectedDimensions: ['date'],
    pivotTableStats: null,
    expandedRows: {},
    expandedPixelRows: {},
    shouldHideZeroRows: true,
    timeouts: {},
    dimensionItems: [
        {
            type: 'dimension',
            id: _.uniqueId('gender_'),
            groupings: ['gender'],
            serverName: 'gender',
        },
        {
            type: 'dimension',
            id: _.uniqueId('age_'),
            groupings: ['age'],
            serverName: 'age',
        },
        {
            type: 'dimension',
            id: _.uniqueId('geo_country_region_'),
            groupings: ['geo_country_region'],
            serverName: 'region',
        },
        {
            type: 'dimension',
            id: _.uniqueId('geo_country_'),
            groupings: ['geo_country'],
            serverName: 'country',
        },
        {
            type: 'dimension',
            id: _.uniqueId('device_os_'),
            groupings: ['device_os'],
            serverName: 'os',
        },
        {
            type: 'dimension',
            id: _.uniqueId('markup_id_'),
            groupings: ['markup_id'],
            serverName: 'markup_id',
        },
    ],
    heatmapItems: [
        {
            type: 'heatmap',
            id: _.uniqueId('hour|day_of_week_'),
            groupings: ['day_of_week', 'hour'],
            serverName: 'time',
        },
    ],
    selectedSubevents: [],
};

function createMetricConfig({ campaign, isClientReportPage }) {
    let metrics = [
        {
            name: 'impressions',
            label: 'Impressions',
            category: 'Performance',
            selected: true,
            format: 'thousands',
        },
        {
            name: 'clicks',
            label: 'Clicks',
            category: 'Performance',
            selected: true,
            format: 'thousands',
        },
        {
            name: 'ctr',
            label: 'CTR',
            category: 'Performance',
            selected: true,
            format: 'percentage',
        },
        {
            name: 'vcr',
            label: 'VCR',
            category: 'Performance',
            selected: false,
            format: 'percentage',
        },
        {
            name: 'unique_users',
            label: 'Unique Users',
            category: 'Performance',
            selected: false,
            format: 'thousands',
        },
        {
            name: 'owner_total_media_cost_local',
            label: 'Total Cost',
            category: 'Performance',
            selected: true,
            format: 'dollar',
        },
        {
            name: 'owner_media_cost_2_local',
            label: 'Media Cost',
            category: 'Performance',
            selected: false,
            format: 'dollar',
            depth: 1,
        },
        {
            name: 'data_cost',
            label: 'Data Cost',
            category: 'Performance',
            selected: false,
            format: 'dollar',
            depth: 1,
        },
        {
            name: 'third_party_fees',
            label: 'Third Party Fees',
            category: 'Performance',
            selected: false,
            format: 'dollar',
            depth: 1,
        },
        {
            name: 'owner_total_media_cost_local_ecpm',
            label: 'Total Cost eCPM',
            category: 'Performance',
            selected: false,
            format: 'dollar',
        },
        {
            name: 'owner_total_media_cost_local_ecpc',
            label: 'Total Cost eCPC',
            category: 'Performance',
            selected: false,
            format: 'dollar',
        },
        {
            name: 'owner_total_media_cost_local_ecpcv',
            label: 'Total Cost eCPCV',
            category: 'Performance',
            selected: false,
            format: 'dollar',
        },
        {
            name: 'win_rate',
            label: 'Win Rate',
            category: 'Performance',
            selected: false,
            format: 'percentage',
        },
        {
            name: 'revenue',
            label: isClientReportPage ? 'Spend' : 'Revenue',
            category: 'Performance',
            selected: true,
            format: 'dollar',
        },
        {
            name: 'erpm',
            label: 'Revenue eCPM',
            category: 'Performance',
            selected: false,
            format: 'dollar',
        },
        {
            name: 'erpc',
            label: 'Revenue eCPC',
            category: 'Performance',
            selected: false,
            format: 'dollar',
        },
        {
            name: 'revenue_ecpcv',
            label: 'Revenue eCPCV',
            category: 'Performance',
            selected: false,
            format: 'dollar',
        },
    ];

    metrics = removeHiddenMetrics({
        metrics,
        campaign,
        isClientReportPage,
    });

    metrics = addCampaignMetrics({
        metrics,
        campaign,
    });

    return metrics;
}

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        init: (state, campaignId) => ({ ...state, campaignId, isLoading: true, selectedSubevents: [] }),
        initSuccess: (state, { stats, campaign, isClientReportPage }) => {
            const environmentSettings = getEnvironmentSettings();
            const canUseNewDimensions = environmentSettings.useNewReportingDimensions();

            const metrics = createMetricConfig({
                campaign,
                isClientReportPage,
            });

            const initialDimensions = [...initialState.dimensions];

            if (campaign.flightPacingStrategy === 'campaign') {
                initialDimensions.push({
                    name: 'flight_id',
                    label: 'Flight',
                });
            }

            if (canUseNewDimensions) {
                initialDimensions.push({ name: 'geo_city', label: 'City' });
                initialDimensions.push({ name: 'creative_channel', label: 'Creative Channel' });
                initialDimensions.push({ name: 'creative_size', label: 'Creative Size' });
                initialDimensions.push({ name: 'video_length', label: 'Video Length' });
                initialDimensions.push({ name: 'inventory_id', label: 'App / Site ID' });
                initialDimensions.push({ name: 'inventory_name', label: 'App / Site Name' });
                initialDimensions.push({ name: 'app_store_cat', label: 'App / Site Category' });
                initialDimensions.push({ name: 'pmp_id', label: 'Private Deal ID' });
                initialDimensions.push({ name: 'pmp_deal_name', label: 'Private Deal Name' });
                initialDimensions.push({ name: 'inventory_type', label: 'Environment' });
                initialDimensions.push({ name: 'exchange_id', label: 'Exchange' });
                initialDimensions.push({ name: 'video_placement_name', label: 'Video Placement' });
                initialDimensions.push({ name: 'video_position_name', label: 'Video Position' });
                initialDimensions.push({ name: 'fsa', label: 'Postal Code' });
                initialDimensions.push({ name: 'device_type_name', label: 'Device Type' });
                initialDimensions.push({ name: 'device_language', label: 'Device Language' });
                initialDimensions.push({ name: 'device_carrier', label: 'Device Carrier' });
                initialDimensions.push({ name: 'conversion_lag', label: 'Conversion Lag' });
            }

            if (
                canUseNewDimensions &&
                flags.isEnabled('en_3511_snowflake_dimensions_lower_priority')
            ) {
                initialDimensions.push({ name: 'device_model', label: 'Device Model' });
                initialDimensions.push({ name: 'video_skippable', label: 'Video Skippable' });
                initialDimensions.push({
                    name: 'pmp_deal_source_type',
                    label: 'Private Deal Source Type',
                });
                initialDimensions.push({ name: 'audience_category', label: 'Audience Category' });
                initialDimensions.push({
                    name: 'audience_subcategory',
                    label: 'Audience Subcategory',
                });
            }

            let dimensions = removeHiddenDimensions({
                dimensions: initialDimensions,
                campaign,
                isClientReportPage,
            });

            return {
                ...state,
                pivotTableStats: stats,
                campaign,
                metrics,
                dimensions,
                isLoading: false,
            };
        },
        initCampaign: (state, { campaign }) => {
            return {
                ...state,
                campaign,
            };
        },
        toggleMetric: (state, metric) => {
            const metrics = _.map(state.metrics, _metric => {
                return {
                    ..._metric,
                    selected: metric.name === _metric.name && metric.label === _metric.label && !_metric.isHeader ? !metric.selected : _metric.selected,
                }
            });

            const hasNoSelectedMetrics = _.filter(metrics, metric => metric.selected).length === 0;
            if (hasNoSelectedMetrics) {
                return state;
            }

            return {
                ...state,
                metrics,
            };
        },
        updateDimensions: (state, selectedDimensions) => ({
            ...state,
            selectedDimensions,
            isLoading: true,
        }),
        addDimension: (state, option) => {
            let selectedDimensions;
            if (_.includes(state.selectedDimensions, option.value)) {
                selectedDimensions = _.filter(
                    state.selectedDimensions,
                    name => name !== option.value
                );
            } else {
                selectedDimensions = _.concat(state.selectedDimensions, option.value);
            }
            return {
                ...state,
                selectedDimensions,
                isLoading: true,
            };
        },
        fetchStats: (state, payload) => ({
            ...state,
            campaignId: payload || state.campaignId,
            isLoading: true,
        }),
        fetchStatsSuccess: (state, stats) => ({
            ...state,
            pivotTableStats: stats,
            isLoading: false,
        }),
        sortColumn: (state, column) => {
            let sortDirection = 'asc';
            if (state.sortColumn === column.name) {
                if (state.sortDirection === 'asc') {
                    sortDirection = 'desc';
                } else {
                    sortDirection = 'asc';
                }
            }

            return {
                ...state,
                sortColumn: column.name,
                sortDirection,
            };
        },
        toggleRow: (state, row) => {
            return {
                ...state,
                expandedRows: {
                    ...state.expandedRows,
                    [row.id]: !row.isExpanded,
                }
            }
        },
        togglePixelRow: (state, row) => {
            return {
                ...state,
                expandedPixelRows: {
                    ...state.expandedPixelRows,
                    [row.pixelRowID]: !row.isPixelRowExpanded,
                }
            }
        },
        toggleHideZeroRows: state => ({
            ...state,
            shouldHideZeroRows: !state.shouldHideZeroRows,
        }),
        exportTable: state => state,
        addTimeouts: (state, payload) => {
            const newTimeouts = payload.reduce((acc, timeoutConfig) => {
                const newConfig = { ...timeoutConfig, ref: null };

                // Check if a timeout with this ID already exists and remove it first.
                if (
                    state.timeouts &&
                    state.timeouts[timeoutConfig.id] &&
                    state.timeouts[timeoutConfig.id].ref
                ) {
                    clearTimeout(state.timeouts[timeoutConfig.id].ref);
                }

                newConfig.ref = setTimeout(() => {
                    toastr.clear();
                    toastr[newConfig.level](newConfig.text(), newConfig.title, TOASTR_OPTIONS);
                }, newConfig.timeout);
                return {
                    ...acc,
                    [newConfig.id]: newConfig,
                };
            }, {});

            return {
                ...state,
                timeouts: {
                    ...state.timeouts,
                    ...newTimeouts,
                },
            };
        },
        removeTimeouts: (state, payload) => {
            const newTimeouts = { ...state.timeouts };
            if (state.timeouts && Object.keys(state.timeouts).length) {
                payload.forEach(timeoutId => {
                    clearTimeout(state.timeouts[timeoutId].ref);
                    delete newTimeouts[timeoutId];
                });
            }

            return {
                ...state,
                timeouts: newTimeouts,
            };
        },
        fetchDimensionItemData: (state) => {
            return {
                ...state,
            }
        },
        initializeDimensions: (state) => {
            return {
                ...state,
            }
        },
        toggleSubevent: (state, payload) => {
            return {
                ...state,
                selectedSubevents: payload.values,
            }
        },
    },
});

function getDimensionsClientToServerName() {
    const DIMENSIONS_CLIENT_TO_SERVER_NAME = {};
    _.each(getDimensions(), dimension => {
        DIMENSIONS_CLIENT_TO_SERVER_NAME[dimension.name] = dimension.serverName;
    });

    return DIMENSIONS_CLIENT_TO_SERVER_NAME;
}

function addCampaignMetrics({ metrics, campaign }) {
    const isDisabledRevenueModel = campaign.revenueModel === RevenueModelMapping.Disabled;

    const beaconMetrics = _.map(campaign.beacons, beacon => {
        return {
            name: `event_${beacon.name}`,
            label: beacon.label,
            category: 'Engagements',
            selected: false,
            format: 'thousands',
        }
    });


    let overallBeaconMetrics;
    if (!_.isEmpty(beaconMetrics)) {
        overallBeaconMetrics = {
            name: 'overall_engagements',
            label: 'Overall Engagements',
            category: 'Engagements',
            selected: false,
            format: 'thousands',
        };
    }

    let overallConversionMetric = [];
    if (_.size(campaign.advertiserConversions) || _.size(campaign.conversions)) {
        overallConversionMetric = [
            {
                name: `conv_overall`,
                label: 'Total',
                category: 'Conversion',
                subcategory: 'Overall Conversions',
                selected: false,
                meta: {
                    column: 'Total',
                    reporting_name: 'Overall Conversions',
                },
                format: 'thousands',
                depth: 0,
            },
            {
                name: `conv_overall_imp`,
                label: 'View-through',
                category: 'Conversion',
                subcategory: 'Overall Conversions',
                selected: false,
                meta: {
                    column: 'View-through',
                    reporting_name: 'Overall Conversions',
                },
                format: 'thousands',
                depth: 1,
            },
            {
                name: `conv_overall_click`,
                label: 'Click-through',
                category: 'Conversion',
                subcategory: 'Overall Conversions',
                selected: false,
                meta: {
                    column: 'Click-through',
                    reporting_name: 'Overall Conversions',
                },
                format: 'thousands',
                depth: 1,
            },
            {
                name: `conv_overall_total_cost_ecpa`,
                label: 'Total Cost eCPA',
                category: 'Conversion',
                subcategory: 'Overall Conversions',
                selected: false,
                meta: {
                    column: 'Total Cost eCPA',
                    reporting_name: 'Overall Conversions',
                },
                format: 'thousands',
                depth: 1,
            },
        ];

        if (!isDisabledRevenueModel) {
            overallConversionMetric.push(
                {
                    name: `conv_overall_revenue_ecpa`,
                    label: 'Revenue eCPA',
                    category: 'Conversion',
                    subcategory: 'Overall Conversions',
                    selected: false,
                    meta: {
                        column: 'Revenue eCPA',
                        reporting_name: 'Overall Conversions',
                    },
                    format: 'thousands',
                    depth: 1,
                }
            )
        }
    }

    let advertiserMetrics = [];
    _(campaign.advertiserConversions)
        .map(conversion => {
            const countColumnLabel = !flags.isEnabled('en_4598_new_report_conversion_columns') ? 'Count' : conversion.reporting_name;
            const countEventValueLabel = !flags.isEnabled('en_4598_new_report_conversion_columns') ? 'Event Value' : `${conversion.reporting_name} Value`;

            // First add the Total column and then add the other ones.
            if (conversion.dynamic_data) {
                advertiserMetrics = advertiserMetrics.concat([
                    {
                        name: `conv_total_adv_${conversion.event_name}`,
                        label: 'Total',
                        category: 'Conversion',
                        subcategory: 'Advertiser Pixels',
                        isDynamicData: true,
                        dynamicData: conversion.dynamic_data,
                        selected: false,
                        meta: {
                            column: countColumnLabel,
                            reporting_name: conversion.reporting_name,
                        },
                        format: 'thousands',
                        depth: 1,
                    },
                ]);
            }

            advertiserMetrics = advertiserMetrics.concat([
                {
                    name: `conv_total_imp_adv_${conversion.event_name}`,
                    label: 'View-through',
                    category: 'Conversion',
                    subcategory: 'Advertiser Pixels',
                    selected: false,
                    meta: {
                        column: 'View-through',
                        reporting_name: conversion.reporting_name,
                    },
                    format: 'thousands',
                    depth: 1,
                },
                {
                    name: `conv_total_click_adv_${conversion.event_name}`,
                    label: 'Click-through',
                    category: 'Conversion',
                    subcategory: 'Advertiser Pixels',
                    selected: false,
                    meta: {
                        column: 'Click-through',
                        reporting_name: conversion.reporting_name,
                    },
                    format: 'thousands',
                    depth: 1,
                },
                {
                    name: `conv_total_cost_ecpa_adv_${conversion.event_name}`,
                    label: 'Total Cost eCPA',
                    category: 'Conversion',
                    subcategory: 'Advertiser Pixels',
                    dynamicData: conversion.dynamic_data,
                    meta: {
                        column: 'Total Cost eCPA',
                        reporting_name: conversion.reporting_name,
                    },
                    format: 'dollar',
                    depth: 1,
                },
            ]);

            if (!isDisabledRevenueModel) {
                advertiserMetrics.push(
                    {
                        name: `conv_revenue_ecpa_adv_${conversion.event_name}`,
                        label: 'Revenue eCPA',
                        category: 'Conversion',
                        subcategory: 'Advertiser Pixels',
                        selected: false,
                        meta: {
                            column: 'Revenue eCPA',
                            reporting_name: conversion.reporting_name,
                        },
                        format: 'dollar',
                        depth: 1,
                    }
                );
            }

            if (conversion.dynamic_data) {
                // Create the dynamic_data columns.
                const dynamicDataColumns = conversion.dynamic_data.map(dynamicData => ({
                    name: dynamicData,
                    label: dynamicData.split('|')[1] || ' Unknown',
                    category: 'Conversion',
                    subcategory: 'Advertiser Pixels',
                    selected: false,
                    meta: {
                        column: dynamicData.split('|')[1] || ' Unknown',
                        reporting_name: conversion.reporting_name,
                    },
                    format: 'thousands',
                    depth: 1,
                }))

                advertiserMetrics = advertiserMetrics.concat([
                    {
                        name: `conv_total_value_adv_${conversion.event_name}`,
                        label: 'Event Value',
                        category: 'Conversion',
                        subcategory: 'Advertiser Pixels',
                        isDynamicData: true,
                        selected: false,
                        dynamicData: conversion.dynamic_data,
                        meta: {
                            column: countEventValueLabel,
                            reporting_name: conversion.reporting_name,
                        },
                        depth: 1,
                        // Note there is no format attribute since we don't want to format this value, just leave it as is.
                    },
                ], dynamicDataColumns);
            }
        })
        .flatten()
        .value();

    let conversionMetrics = [];
    _(campaign.conversions)
        .map(conversion => {
            const countColumnLabel = !flags.isEnabled('en_4598_new_report_conversion_columns') ? 'Count' : conversion.reporting_name;
            const countEventValueLabel = !flags.isEnabled('en_4598_new_report_conversion_columns') ? 'Event Value' : `${conversion.reporting_name} Value`;

            // First add the total column and then add the other ones.
            if (conversion.dynamic_data) {
                conversionMetrics = conversionMetrics.concat([
                    {
                        name: `conv_${conversion.event_name}`,
                        label: 'Total',
                        category: 'Conversion',
                        subcategory: 'Campaign Pixels',
                        selected: false,
                        isDynamicData: true,
                        dynamicData: conversion.dynamic_data,
                        meta: {
                            column: countColumnLabel,
                            reporting_name: conversion.reporting_name,
                        },
                        format: 'thousands',
                        depth: 1,
                    },
                ]);
            }

            conversionMetrics = conversionMetrics.concat([
                {
                    name: `conv_total_${conversion.event_name}`,
                    label: conversion.reporting_name,
                    category: 'Conversion',
                    subcategory: 'Campaign Pixels',
                    selected: false,
                    meta: {
                        column: 'Total',
                        reporting_name: conversion.reporting_name,
                    },
                    format: 'thousands',
                    depth: 0,
                },
                {
                    name: `conv_total_imp_${conversion.event_name}`,
                    label: 'View-through',
                    category: 'Conversion',
                    subcategory: 'Campaign Pixels',
                    selected: false,
                    meta: {
                        column: 'View-through',
                        reporting_name: conversion.reporting_name,
                    },
                    format: 'thousands',
                    depth: 1,
                },
                {
                    name: `conv_total_click_${conversion.event_name}`,
                    label: 'Click-through',
                    category: 'Conversion',
                    subcategory: 'Campaign Pixels',
                    selected: false,
                    meta: {
                        column: 'Click-through',
                        reporting_name: conversion.reporting_name,
                    },
                    format: 'thousands',
                    depth: 1,
                },
                {
                    name: `conv_total_cost_ecpa_${conversion.event_name}`,
                    label: 'Total Cost eCPA',
                    category: 'Conversion',
                    subcategory: 'Campaign Pixels',
                    selected: false,
                    meta: {
                        column: 'Total Cost eCPA',
                        reporting_name: conversion.reporting_name,
                    },
                    format: 'dollar',
                    depth: 1,
                },
            ]);

            if (!isDisabledRevenueModel) {
                conversionMetrics.push(
                    {
                        name: `conv_revenue_ecpa_${conversion.event_name}`,
                        label: 'Revenue eCPA',
                        category: 'Conversion',
                        subcategory: 'Campaign Pixels',
                        selected: false,
                        meta: {
                            column: 'Revenue eCPA',
                            reporting_name: conversion.reporting_name,
                        },
                        format: 'dollar',
                        depth: 1,
                    }
                );
            }

            if (conversion.dynamic_data) {
                // Create the dynamic_data columns.
                const dynamicDataColumns = conversion.dynamic_data.map(dynamicData => ({
                    name: dynamicData,
                    label: dynamicData.split('|')[1] || 'Unknown',
                    category: 'Conversion',
                    subcategory: 'Campaign Pixels',
                    selected: false,
                    meta: {
                        column: dynamicData.split('|')[1] || 'Unknown',
                        reporting_name: conversion.reporting_name,
                    },
                    format: 'thousands',
                    depth: 1,
                }));

                conversionMetrics = conversionMetrics.concat([
                    {
                        name: `conv_total_value_${conversion.event_name}`,
                        label: 'Event Value',
                        category: 'Conversion',
                        subcategory: 'Campaign Pixels',
                        selected: false,
                        isDynamicData: true,
                        dynamicData: conversion.dynamic_data,
                        meta: {
                            column: countEventValueLabel,
                            reporting_name: conversion.reporting_name,
                        },
                        depth: 1,
                        // Note there is no format attribute since we don't want to format this value, just leave it as is.
                    },
                ], dynamicDataColumns);
            }
        })
        .flatten()
        .value();

    return _.concat(
        metrics,
        overallBeaconMetrics,
        beaconMetrics,
        overallConversionMetric,
        advertiserMetrics,
        conversionMetrics
    ).filter(metric => metric);
}

function removeHiddenDimensions({ dimensions, campaign, isClientReportPage }) {
    return _(dimensions)
        .filter(dimension => {
            if (!isClientReportPage) {
                return true;
            }

            if (dimension.name === 'adgroup' && isDimensionShared(campaign, 'adgroup')) {
                return true;
            }

            return _.find(campaign.sharing_settings.report_tabs.explore.dimensions, {
                name: getDimensionsClientToServerName()[dimension.name],
                shared: true,
            });
        })
        .filter(dimension => {
            if (dimension.name !== 'audience_segment') {
                return true;
            }

            if (campaign.hasAudiences) {
                return true;
            }

            return false;
        })
        .value();
}

function removeHiddenMetrics({ metrics, campaign, isClientReportPage }) {
    return _(metrics)
        .filter(metric => {
            if (metric.name !== 'owner_media_cost_2_local') {
                return true;
            }

            if (campaign.isMediaCostMetricEnabled) {
                return true;
            } else {
                return false;
            }
        })
        .filter(metric => {
            if (metric.name === 'unique_users') {
                const isUniqueUsersEnabled = campaign.isUniqueUsersDisabled !== true;

                return isUniqueUsersEnabled;
            }

            return true;
        })
        .filter(metric => {
            if (campaign.billing_enabled) {
                return true;
            }

            if (_.includes(['erpm', 'erpc', 'revenue'], metric.name)) {
                return false;
            } else {
                return true;
            }
        })
        .filter(metric => {
            // only apply sharing to clients
            if (!isClientReportPage) {
                return true;
            }

            const metricConfig = _.find(campaign.sharing_settings.metrics, _metric => {
                if (_metric.name === metric.name) {
                    return true;
                } else {
                    return false;
                }
            });

            if (!metricConfig) {
                return false;
            }

            return metricConfig.shared;
        })
        .value();
}

export * from './middleware';
export * from './hook';
export * from './selectors';
